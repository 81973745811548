import {
    Call,
    CallWithFormData,
    cleanEmpty,
    objectToFormData,
} from "../../../../helpers/fetch";
import { types } from "../../../types";
import { startUILoading, stopUILoading } from "../ui/ui";
import Swal from "sweetalert2";

export const startGetConfig = () => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call("auth/config/global", "GET");
        if (resp.success) {
            dispatch(setConfig(resp.success.config));
        }
        dispatch(stopUILoading());
    };
};
export const startSetSettings = (configs) => {
    return (dispatch) => dispatch(setConfig(configs));
};

export const startSaveSettings = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await CallWithFormData(
            "auth/config/save",
            "POST",
            objectToFormData(values)
        );

        if (resp.success) {
            dispatch(setConfig(resp.success.config));
            Swal.fire({
                title: "Se cambió la configuracion",
                text: "Tu configuracion fue guardada",
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Recargar página",
            }).then((result) => {
                location.reload();
            });
        }
        dispatch(stopUILoading());
    };
};

export const startTogglePracticesProfessional = (isActive) => {
    return async (dispatch) => {
        if (isActive) {
            togglePracticesProfessional(dispatch, isActive);
            return;
        }

        const result = await Swal.fire({
            title: "Habilitar formulario",
            input: "text",
            inputPlaceholder: "Ciclo escolar",
            icon: "info",
            text: "Escribe el ciclo al que sean asignados los alumnos",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Si, habilitar!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
        });

        if (result.isConfirmed) {
            if (result.value) {
                togglePracticesProfessional(dispatch, isActive, result.value);
            } else {
                await Swal.fire({
                    title: "Formario incompleto",
                    icon: "warning",
                    text: "Debes establecer un nombre de ciclo",
                });
            }
        }
    };
};

export const startToggleServiceSocial = (isActive) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call("auth/config/toggle", "PUT", {
            is_active_service_social: !isActive,
        });

        if (resp.success) {
            dispatch(setConfig(resp.success.config));
        }

        dispatch(stopUILoading());
    };
};

const togglePracticesProfessional = async (
    dispatch,
    isActive,
    cycle = null
) => {
    dispatch(startUILoading());
    const resp = await Call("auth/config/toggle", "PUT", {
        is_active_professional_practice: !isActive,
        cycle,
    });
    if (resp.success) {
        dispatch(setConfig(resp.success.config));
    }
    dispatch(stopUILoading());
};

const setConfig = (data) => ({
    type: types.setConfig,
    payload: data,
});


