import { createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";

const initialState = {
    report: null,
};

export const ServicesByConcept = createSlice({
    name: "ServicesByConcept",
    initialState,
    reducers: {
        setReport: (state, action) => {
            state.report = action.payload;
        },
        clearReport: (state) => {
            state.report = initialState.report;
        },
    },
});

export const { setReport, clearReport } = ServicesByConcept.actions;

export default ServicesByConcept.reducer;

export const startShowReport = ({
    cycles,
    curricula_tabulator_ids,
    from,
    to,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const cycles_data = `${cycles.year}-${cycles.sub_cycle}`;

        const params = new URLSearchParams();

        params.set("cycles", cycles_data);
        params.set(
            "curricula_tabulator_ids",
            curricula_tabulator_ids?.map((cti) => cti?.value)?.join(",")
        );
        params.set("from", from);
        params.set("to", to);

        const resp = await Call(
            `operators/reports/services/by/concept`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setReport(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startExportReport = ({
    cycles,
    curricula_tabulator_ids,
    from,
    to,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const cycles_data = `${cycles.year}-${cycles.sub_cycle}`;

        const params = new URLSearchParams();

        params.set("export", 1);
        params.set("cycles", cycles_data);
        params.set(
            "curricula_tabulator_ids",
            curricula_tabulator_ids?.map((cti) => cti?.value)?.join(",")
        );
        params.set("from", from);
        params.set("to", to);

        const response = await CallWithFormDataFile(
            `operators/reports/services/by/concept`,
            "GET",
            params.toString()
        );
        downloadBlob(response, `Reporte de servicios por concepto`);

        dispatch(stopUILoading());
    };
};
