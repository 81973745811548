import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    Call,
    CallWithFormData,
    CallWithFormDataFile,
    cleanEmpty,
    downloadBlob,
    objectToFormData,
} from "../../../helpers/fetch";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
    },
    openModal: false,
    receipt: null,
    response: null,
    overduePortfolio: null,
    openModalAccount: false,
};

export const paymentScreen = createSlice({
    name: "paymentScreen",
    initialState,
    reducers: {
        setPayments: (state, action) => {
            state.pagination = action.payload;
        },
        setOpenModal: (state) => {
            state.openModal = true;
        },
        setCloseModal: (state) => {
            state.openModal = false;
        },
        setOpenModalAccount: (state) => {
            state.openModalAccount = true;
        },
        setCloseModalAccount: (state) => {
            state.openModalAccount = false;
            state.response = null;
        },
        setReceipt: (state, action) => {
            state.receipt = action.payload;
        },
        setResponse: (state, action) => {
            state.response = action.payload;
        },
        setOverduePortfolio: (state, action) => {
            state.overduePortfolio = action.payload;
        },
        setCleanOverduePortfolio: (state) => {
            state.overduePortfolio = null;
        },
    },
});

export const {
    setPayments,
    setOpenModal,
    setCloseModal,
    setOpenModalAccount,
    setCloseModalAccount,
    setResponse,
    setCleanOverduePortfolio,
    setOverduePortfolio,
    setReceipt,
} = paymentScreen.actions;

export default paymentScreen.reducer;

export const startShow = (values) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        params.set("page", values.page);
        params.set("q", values.query);
        params.set("per_page", values.number_rows);
        if (values.from) {
            params.set("from", values.from);
        }
        if (values.to) {
            params.set("to", values.to);
        }
        if (values.country) {
            params.set("country", values.country);
        }
        if (values.academic_record_id) {
            params.set("academic_record_id", values.academic_record_id);
        }
        if (values.invoice_status) {
            params.set("invoice_status", values.invoice_status);
        }
        if (values.payable_type) {
            params.set("payable_type", values.payable_type);
        }
        if (values.payment_method) {
            params.set("payment_method", values.payment_method);
        }

        const { session } = getState().auth;
        let url = "";

        if (session === "operator") {
            url = "services/payments/show";
        } else {
            url = "services/payments/show/student";
        }

        const resp = await Call(url, "GET", params.toString());

        if (resp.success) {
            dispatch(setPayments(resp.success.payments));
        }
        dispatch(stopUILoading());
    };
};
export const startShowPaymentHistory = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        params.set("page", values.page);
        params.set("q", values.query);
        params.set("per_page", values.number_rows);
        if (values.from) {
            params.set("from", values.from);
        }
        if (values.to) {
            params.set("to", values.to);
        }
        if (values.country) {
            params.set("country", values.country);
        }
        if (values.academic_record_id) {
            params.set("academic_record_id", values.academic_record_id);
        }

        // const resp = await Call(
        //     `services/payu/get/payment/academic_record`,
        //     "GET",
        //     params.toString()
        // );

        const resp = await Call(
            `services/payments/get/academic_record`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setPayments(resp.success));
        }
        dispatch(stopUILoading());
    };
};

export const startExportExcel = (
    query,
    from,
    to,
    country,
    payable_type,
    payment_method
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const values = {
            from: from,
            to: to,
            country: country,
            payable_type,
            payment_method,
        };
        let formData = new FormData();

        if (query) {
            formData.append("q", query);
        }
        formData.append("from", from);
        formData.append("to", to);
        formData.append("country", country);
        formData.append("payable_type", payable_type);
        formData.append("payment_method", payment_method);

        const resp = await CallWithFormDataFile(
            `services/payments/export`,
            "POST",
            formData
        );

        downloadBlob(resp, `Reporte cobranza.xlsx`);
        dispatch(stopUILoading());
    };
};

export const startExportExcelAll = () => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        const { tiny_name } = getState().config;

        let formData = new FormData();

        formData.append("export", true);

        const resp = await CallWithFormDataFile(
            `services/payments/export-with-concepts`,
            "POST",
            formData
        );

        downloadBlob(
            resp,
            `Reporte cobranza general con conceptos ${tiny_name}.xlsx`
        );
        dispatch(stopUILoading());
    };
};

export const startExportOverduePortfolio = (cycle_ids) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let formData = new FormData();

        formData.append("cycle_ids", cycle_ids);
        formData.append("export", 1);

        const resp = await CallWithFormDataFile(
            `operators/reports/payments/overdue_portfolio`,
            "POST",
            formData
        );

        downloadBlob(resp, `Reporte de pagos vencidos.xlsx`);
        dispatch(stopUILoading());
    };
};

export const startGetOverduePortfolio = ({
    cycle_id,
    curricula,
    status,
    degree,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let formData = new FormData();

        formData.append("cycle_ids", cycle_id);
        formData.append("export", 0);
        formData.append("status", status.map((s) => s.value).join(","));
        formData.append("curricula", curricula.map((c) => c.value).join(","));
        formData.append("degree", degree.map((d) => d.value).join(","));

        const resp = await CallWithFormData(
            `operators/reports/payments/overdue_portfolio`,
            "POST",
            formData
        );

        dispatch(setOverduePortfolio(resp.success));
        dispatch(stopUILoading());
    };
};

export const starUpdateAccounts = () => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estas seguro que deseas actualizar los estados de cuenta?",
            icon: "warning",
            text: "Una vez realizado el movimiento no podrás revertir los cambios",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "¡Si, actualizar ahora!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
        });

        if (result.isConfirmed) {
            dispatch(startUILoading());
            const resp = await Call(
                `operators/students/account-status/update/massive`,
                "POST"
            );

            if (resp.success) {
                dispatch(setResponse(resp.success));

                Swal.fire({
                    title: "Éxito",
                    text: "Se actualizaron los estados de cuenta.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
            }

            dispatch(stopUILoading());
        }
    };
};

export const starUpdateAccountsByCycle = (cycle_id, callback = () => {}) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estas seguro que deseas actualizar los estados de cuenta de este ciclo?",
            icon: "warning",
            text: "Una vez realizado el movimiento no podrás revertir los cambios",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "¡Si, actualizar ahora!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
        });

        if (result.isConfirmed) {
            dispatch(startUILoading());

            let formData = new FormData();

            formData.append("cycle_id", cycle_id);

            const resp = await Call(
                `operators/students/account-status/update/cycle`,
                "POST",
                { cycle_id }
            );

            if (resp.success) {
                callback();
                Swal.fire({
                    title: "Éxito",
                    text: "Se actualizaron los estados de cuenta.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
            }

            dispatch(stopUILoading());
        }
    };
};

export const starImportEXP = (exp) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estas seguro que deseas este archivo?",
            icon: "warning",
            text: "Una vez realizado el movimiento no podrás revertir los cambios",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "¡Si, importar ahora!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
        });

        if (result.isConfirmed) {
            dispatch(startUILoading());

            let formData = new FormData();

            formData.append("exp", exp);

            const resp = await CallWithFormData(
                `services/bbva/import/exp`,
                "POST",
                formData
            );

            if (resp.success) {
                dispatch(setCloseModalAccount());

                const { msg, payments_imported_count } = resp.success;

                Swal.fire({
                    title: msg,
                    text: `Pagos importados: ${payments_imported_count}`,
                    icon: "success",
                    timer: 10000,
                });
            } else {
                errorHandler(resp);
            }

            dispatch(stopUILoading());
        }
    };
};

export const startGenerateReceipt = (payment_id) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(`services/payments/by/${payment_id}`, "GET");
        if (resp.success) {
            dispatch(setReceipt(resp.success));
        }
        dispatch(stopUILoading());
    };
};
