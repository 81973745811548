import React, { useEffect } from "react";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { IconApp } from "../../../IconApp";
import { NavLinkApp } from "../../../NavLinkApp";
import SidebarNavAccordion from "../../../SidebarNavAccordion";
import { useDispatch, useSelector } from "react-redux";
import { startUIShowModulesCount } from "../../../../redux/actions/administrador/ui/ui";

export const Modules_TI = () => {
    const dispatch = useDispatch();
    const { hasPermission } = useHasPermission();
    const { color_text_menu_accordion } = useSelector((state) => state.config);

    const canShowAccordion = hasPermission([
        "ADMINISTRADOR",
        "MODULES",
        "MANUALS",
        "SUPPORT_TI",
        "FORMATS",
    ]);

    useEffect(() => {
        if (canShowAccordion) {
            dispatch(startUIShowModulesCount("Modules_TI"));
        }
    }, [canShowAccordion]);

    return (
        <div>
            {canShowAccordion && (
                <SidebarNavAccordion
                    title={"Sistemas"}
                    itemLevel={0}
                    color={color_text_menu_accordion}
                    iconLeftClassName="fa-solid fa-computer fa-xl"
                >
                    {hasPermission("ADMINISTRADOR") && (
                        <NavLinkApp
                            name="Usuarios"
                            to="/app/users/list"
                            icon={
                                <IconApp iconClassName="fas fa-user-friends c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("MODULES") && (
                        <NavLinkApp
                            name="Módulos"
                            to="/app/modules/list"
                            icon={
                                <IconApp iconClassName="fa-solid fa-rectangle-history-circle-user c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("ROLES") && (
                        <NavLinkApp
                            name="Roles"
                            to="/app/roles"
                            icon={
                                <IconApp iconClassName="fas fas fa-user-shield c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("MANUALS") && (
                        <NavLinkApp
                            name="Manuales"
                            to="/app/manuals/administrator"
                            icon={
                                <IconApp iconClassName="fas fa-book c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("FORMATS") && (
                        <NavLinkApp
                            name="Gestor de documentos"
                            to="/app/formats/administrator"
                            icon={
                                <IconApp iconClassName="fas fa-file-alt c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("SUPPORT_TI") && (
                        <NavLinkApp
                            name="Soporte técnico"
                            to="/app/soporte/tickets"
                            icon={
                                <IconApp iconClassName="fas fa-tools c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission("ADMINISTRADOR") && (
                        <NavLinkApp
                            name="Configuraciones"
                            to="/app/settings"
                            icon={
                                <IconApp iconClassName="fas fa-cog c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission("ADMINISTRADOR") && (
                        <NavLinkApp
                            name="Empresas"
                            to="/app/enterprises"
                            icon={
                                <IconApp iconClassName="fas fa-buildings c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                </SidebarNavAccordion>
            )}
        </div>
    );
};
