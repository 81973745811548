import Swal from "sweetalert2";

export const deleteConfirmModal = async (
    callback = () => {},
    allowOutsideClick = true,
    allowEnterKey = true
) => {
    const result = await Swal.fire({
        title: "¿Estás seguro de eliminar?",
        icon: "warning",
        text: "¡No podrás revertir esta acción!",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, eliminar",
        confirmButtonColor: "#2e4a6c",
        cancelButtonColor: "#6c757d",
        input: "text",
        inputLabel:
            "Escribe la palabra 'eliminar' para confirmar esta operación.",
        inputAttributes: {
            autocapitalize: "off",
        },
        allowOutsideClick: allowOutsideClick,
        allowEnterKey: allowEnterKey,
    });

    if (result.value === "eliminar") {
        await callback();
    } else if (result.value) {
        Swal.fire("Operación cancelada", "", "info");
    }
};
