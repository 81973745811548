import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    active: null,
    tabulators: [],
    openJustifyDayForm: false,
    openForm: false,
    openFormCopyPayroll: false,
};

export const EADReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.eadSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.eadUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.eadSetTabulators:
            return {
                ...state,
                tabulators: action.payload,
            };
        case types.eadOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.eadCloseModal:
            return {
                ...state,
                openForm: false,
                active: null,
            };
        case types.eadShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.eadOpenModalCopyPayroll:
            return {
                ...state,
                openFormCopyPayroll: true,
            };
        case types.eadCloseModalCopyPayroll:
            return {
                ...state,
                openFormCopyPayroll: false,
                active: null,
            };
        default:
            return state;
    }
};
