import { types } from "../../../types";

const initialState = {
    loading: false,
    sidebarShow: "responsive",
    php: "V. X.X",
    laravel: "V. X.X",
    notifications: [],
    uiShowModulesCount: [],
};

export const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.uiStartLoading:
            return {
                ...state,
                loading: true,
            };
        case types.uiStopLoading:
            return {
                ...state,
                loading: false,
            };
        case types.uiSidebar:
            return {
                ...state,
                sidebarShow: action.payload,
            };
        case types.uiShownotification:
            return {
                ...state,
                notifications: [
                    {
                        id: state.notifications.length,
                        show: true,
                        ...action.payload.notification.data,
                    },
                    ...state.notifications,
                ],
            };
        case types.uiSetNotifications:
            return {
                ...state,
                notifications: action.payload,
            };
        case types.uiSetEnviromentVersions:
            const root = document.getElementById("root");
            return {
                ...state,
                php: root.getAttribute("php"),
                laravel: root.getAttribute("laravel"),
            };
        case types.uiIncrementShowModulesCount:
            return {
                ...state,
                uiShowModulesCount: action.payload
            }
        default:
            return state;
    }
};
