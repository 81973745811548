import { CSidebarNavTitle } from "@coreui/react";
import React, { useEffect } from "react";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { IconApp } from "../../../IconApp";
import { NavLinkApp } from "../../../NavLinkApp";
import SidebarNavAccordion from "../../../SidebarNavAccordion";
import { useDispatch, useSelector } from "react-redux";
import { startUIShowModulesCount } from "../../../../redux/actions/administrador/ui/ui";

export const Modules_Shopping = () => {
    const dispatch = useDispatch();
    const { hasPermission } = useHasPermission();
    const { color_text_menu_accordion } = useSelector((state) => state.config);

    const canShowAccordion = hasPermission([
        "PROVIDERS",
        "PURCHASES_MANAGEMENT",
        "PURCHASES",
    ]);

    useEffect(() => {
        if (canShowAccordion) {
            dispatch(startUIShowModulesCount("Modules_Shopping"));
        }
    }, [canShowAccordion]);
    return (
        <div>
            {canShowAccordion && (
                <SidebarNavAccordion
                    title={"Compras"}
                    itemLevel={0}
                    iconLeftClassName={
                        "fa-solid fa-money-check-dollar fa-xl me-2"
                    }
                    color={color_text_menu_accordion}
                >
                    {hasPermission("PROVIDERS") && (
                        <NavLinkApp
                            name="Proveedores"
                            to="/app/shopping/providers"
                            icon={
                                <IconApp iconClassName="fas fa-truck c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission(["PURCHASES_MANAGEMENT", "PURCHASES"]) && (
                        <NavLinkApp
                            name="Compras"
                            to="/app/shopping/purchases"
                            icon={
                                <IconApp iconClassName="fas fa-shopping-cart c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("BUDGETS") && (
                        <NavLinkApp
                            name="Presupuestos"
                            to="/app/shopping/budgets"
                            icon={
                                <IconApp iconClassName="fas fa-poll c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                </SidebarNavAccordion>
            )}
        </div>
    );
};
