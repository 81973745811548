import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    historyPagination: {
        data: [],
        per_page: 5,
    },
    openForm: false,
    //herrmientas para el UI
    moduleTools: [],
    active: null,
    currentForm: null,
    openConfigModal: false,
};

export const purchasesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.showPurchases:
            return {
                ...state,
                pagination: action.payload.pagination,
                moduleTools: action.payload.moduleTools,
            };

        case types.showPurchasesFilesHistory:
            return {
                ...state,
                historyPagination: action.payload.pagination,
            };
        case types.purchasesOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.purchasesCloseModal:
            return {
                ...state,
                openForm: false,
            };
        case types.purchaseSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.purchaseUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.purchasesSetCurrentForm:
            return {
                ...state,
                currentForm: action.payload,
            };

        case types.purchasesOpenConfigModal:
            return {
                ...state,
                openConfigModal: true,
            };
        case types.purchasesCloseConfigModal:
            return {
                ...state,
                openConfigModal: false,
            };

        default:
            return state;
    }
};
