import { createSlice } from "@reduxjs/toolkit";
import { startUILoading, stopUILoading } from "../../actions/administrador/ui/ui";
import { Call, CallWithFormData, cleanEmpty, objectToFormData } from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";
import { startRenew } from "../../actions/administrador/auth/authStudents";

const initialState = {
    test: true,
};

export const scholarshipRequestScreen = createSlice({
    name: "scholarshipRequestScreen",
    initialState,
    reducers: {
        toggleTest: (state) => {
            state.test = !state.test;
        },
    },
});

export const { toggleTest } = scholarshipRequestScreen.actions;

export default scholarshipRequestScreen.reducer;

export const startCreateScholarshipRequest = (values, setErrors,callback= ()=>{}) => {
    return async (dispatch) => {
        dispatch(startUILoading());
//       values = cleanEmpty(values);
        const resp = await Call(
            `operators/scholarship/request`,
            "PUT",
            values,
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
            dispatch(startRenew());
            callback("can_request", false);

        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
}
