import { types } from "../types";

const initialState = {
    data_groups: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "V",
        "U",
        "W",
        "X",
        "Y",
        "Z",
    ],
    data_grade: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
    ],
    countries: [],
    statesBirth: [],
    statesBirthTutor: [],
    statesResidence: [],
    statesLocation: [],
};

export const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.setCountries:
            return {
                ...state,
                countries: action.payload,
            };

        case types.setStatesBirth:
            return {
                ...state,
                statesBirth: action.payload,
            };
        case types.setStatesBirthTutor:
            return {
                ...state,
                statesBirthTutor: action.payload,
            };
        case types.setStatesResidence:
            return {
                ...state,
                statesResidence: action.payload,
            };

        case types.setStatesLocation:
            return {
                ...state,
                statesLocation: action.payload,
            };

        default:
            return state;
    }
};
