import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addStudentModal: false,
};

export const addStudentApp = createSlice({
    name: "addStudentApp",
    initialState,
    reducers: {
        toggleModal: (state) => {
        state.addStudentModal = !state.addStudentModal;
        },
    },
});

export const { toggleModal } = addStudentApp.actions;

export default addStudentApp.reducer;

