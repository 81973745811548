import { types } from "../../types";

const initialState = {
    students: [],
    count: 0,
    active: JSON.parse(localStorage.getItem("student")) || {},
};

export const algebraixReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.studentsAlgebraixShow:
            return {
                ...state,
                students: action.payload.students,
                count: action.payload.count
            };
        case types.studentsAlgebraixActive:
            return {
                ...state,
                active: {
                    ...action.payload,
                    ...state.active

                }
            };

        case types.studentsAlgebraixUnsetActive:
            return {
                ...state,
                active: {}
            };
        default:
            return state;
    }
};
