
import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    list: [],
    active: null,
    openForm: false,
    openFormUsers: false,
};

export const manualsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.manualList:
            return {
                ...state,
                list: action.payload.data,
            };
        case types.manualShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.manualsetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.manualOpenForm:
            return {
                ...state,
                openForm: true,
            };
        case types.manualCloseForm:
            return {
                ...state,
                openForm: false,
                active: false,
            };
        case types.manualOpenFormUsers:
            return {
                ...state,
                openFormUsers: true,
            };
        case types.manualCloseFormUsers:
            return {
                ...state,
                openFormUsers: false,
                active: false,
            };
        default:
            return state;
    }
};
