import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { Call } from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { errorHandler } from "../../../helpers/errorHandler";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
    },
    openModal: false,
    active: null,
};

export const positiveBalanceScreen = createSlice({
    name: "positiveBalanceScreen",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setOpenModal: (state) => {
            state.openModal = !state.openModal;
        },
        setCloseModal: (state) => {
            state.openModal = false;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        unsetActive: (state) => {
            state.active = null;
        },
    },
});

export const {
    setPagination,
    setOpenModal,
    setCloseModal,
    setActive,
    unsetActive,
} = positiveBalanceScreen.actions;

export default positiveBalanceScreen.reducer;

export const startShowPositiveBalances = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("page", values.page);
        params.set("q", values.query);
        params.set("per_page", values.number_rows);

        const resp = await Call(
            `services/positive-balance`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setPagination(resp.success));
        }

        dispatch(stopUILoading());
    };
};

export const startSavePositiveBalance = (
    { academic_record, note, balance },
    dataFromParent,
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const data = {
            academic_record_id:
                academic_record?.id,
            note,
            balance,
        };
        const resp = await Call(`services/positive-balance`, "POST", data);

        if (resp.success) {
            Swal.fire({
                icon: "success",
                title: "Éxito",
                text: "Se agregó el saldo a favor.",
            });
            dispatch(startShowPositiveBalances(dataFromParent));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startUpdatePositiveBalance = (
    values,
    dataFromParent,
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(
            `services/positive-balance/${values.id}`,
            "POST",
            values
        );

        if (resp.success) {
            Swal.fire({
                icon: "success",
                title: "Éxito",
                text: "Se actualizó el recurso.",
            });
            dispatch(startShowPositiveBalances(dataFromParent));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startDeletePositiveBalance = (id, values, setErrors) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estás seguro de eliminar?",
            icon: "warning",
            text: "No podrás revertir esta acción!",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Si, Eliminar!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            input: "text",
            inputLabel:
                "Escribe la palabra 'eliminar', para confirmar esta operación.",
            inputAttributes: {
                autocapitalize: "off",
            },
        });
        if (result.value === "eliminar") {
            dispatch(startUILoading());
            const resp = await Call(
                `services/positive-balance/${id}`,
                "DELETE"
            );
            if (resp.success) {
                Swal.fire({
                    icon: "success",
                    title: "Éxito",
                    text: "Se eliminó el recurso.",
                });
                dispatch(startShowPositiveBalances(values));
            } else {
                errorHandler(resp, setErrors);
            }
            dispatch(stopUILoading());
        }
    };
};
