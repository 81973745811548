import PropTypes from "prop-types";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { SuspenceFallbackContainer } from "./SuspenceFallbackContainer";
import { FooterApp } from "../FooterApp";
import { useHasPermission } from "../../hooks/useHasPermission";

export const PublicRoute = ({ component: Component, layout = true }) => {
    const { isLogged } = useHasPermission();

    return isLogged ? (
        <Navigate to={"/app/home"} />
    ) : (
        <Suspense fallback={<SuspenceFallbackContainer />}>
            <Component />
            {layout && <FooterApp />}
        </Suspense>
    );
};

PublicRoute.propTypes = {
    component: PropTypes.object.isRequired,
    layout: PropTypes.bool.isRequired,
};
