import { types } from "../../types";

const initialState = {
    list: []
};

export const delayReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DelayShowList:
            return {
                ...state,
                list: action.payload.delay
            };
        default:
            return state;
    }
};
