
import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    list: [],
    active: null,
    openForm: false,
    openFormUsers: false,
};

export const formatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.formatList:
            return {
                ...state,
                list: action.payload.data,
            };
        case types.formatShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.formatsetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.formatOpenForm:
            return {
                ...state,
                openForm: true,
            };
        case types.formatCloseForm:
            return {
                ...state,
                openForm: false,
                active: false,
            };
        case types.formatOpenFormUsers:
            return {
                ...state,
                openFormUsers: true,
            };
        case types.formatCloseFormUsers:
            return {
                ...state,
                openFormUsers: false,
                active: false,
            };
        default:
            return state;
    }
};
