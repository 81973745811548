import { types } from "../../../types";

const initialState = {
    incident_day: null,
    active: {
        observation: "",
        user: {
            employee: {
                username: "",
                schedule: {},
            },
        },
        log: [],
    },
    openIncident: false,
    openForm: null,
    list: [],
    pagination: {
        data: [],
        per_page: 10,
    },
};

export const incidentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.incidentsShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.incidentsSetIncidentsByUser:
            return {
                ...state,
                list: action.payload,
            };
        case types.incidentsSetDay:
            return {
                ...state,
                incident_day: action.payload,
            };

        case types.incidentsOpenForm:
            return {
                ...state,
                openForm: true,
                incident_day: action.payload,
            };
        case types.incidentsCloseForm:
            return {
                ...state,
                openForm: false,
                incident_day: null,
            };

        case types.incidentsSetActive:
            return {
                ...state,
                active: action.payload,
            };

        case types.incidentsOpenIncident:
            return {
                ...state,
                openIncident: true,
                active: action.payload,
            };
        case types.incidentsCloseIncident:
            return {
                ...state,
                openIncident: false,
                active: initialState.active,
            };

        default:
            return state;
    }
};
