import { types } from "../../../types";

const initialState = {
    openModal: false,
    list: [],
    pagination: {
        data: [],
        per_page: 10
    }
};

export const daysOffReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.dayOffShow:
            return {
                ...state,
                pagination: action.payload.pagination
            };
        case types.dayOffOpenModal:
            return {
                ...state,
                openModal: true
            };
        case types.dayOffCloseModal:
            return {
                ...state,
                openModal: false
            };
        default:
            return state;
    }
};
