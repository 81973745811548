import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
    },
    list: [],
    active: null,
    openForm: false,
    openModalSchedules: false,
    punches: { punches: [] },
    all_punches: { punches: [] },
};

export const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.employeeShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.employeeOpenModalSchedules:
            return {
                ...state,
                openModalSchedules: true,
            };
        case types.employeeCloseModalSchedules:
            return {
                ...state,
                openModalSchedules: false,
            };
        case types.employeeSetPunch:
            return {
                ...state,
                punches: action.payload,
            };
        case types.employeeSetAllPunch:
            return {
                ...state,
                all_punches: action.payload,
            };
        case types.employeeUnsetPunch:
            return {
                ...state,
                punches: { punches: [] },
                all_punches: { punches: [] },
            };

        default:
            return state;
    }
};
