import { createSlice } from "@reduxjs/toolkit";
import { startUILoading, stopUILoading } from "../../actions/administrador/ui/ui";
import { Call } from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";

const initialState = {
    pagination: {
        data: [],
        per_page: 10
    },
};

export const gradesEditScreen = createSlice({
    name: 'gradesEditScreen',
    initialState,
    reducers:{
        setPagination: (state, action)=>{
            state.pagination = action.payload;
        },
    }
});

export const {setPagination} = gradesEditScreen.actions;

export default gradesEditScreen.reducer;

//*Actions

export const startGetEditedGrades = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const urlParams = new URLSearchParams();
        if (values?.query !== "")
            urlParams.set("q", values?.query);
        if (values?.number_rows)
            urlParams.set("per_page", values?.number_rows);
        if (values?.page)
            urlParams.set("page", values?.page);
        const resp = await Call(
            `operators/students/grades/show/edit`,
            "GET",
            urlParams.toString()
        );
        if (resp.success) {
            dispatch(setPagination(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};
