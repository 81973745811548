import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormData,
    mergeFormData,
    objectToFormData,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
        total: 0
    },
    active: null,
    list: [],
};

export const PlanningSheets = createSlice({
    name: "PlanningSheets",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        clearList: (state) => {
            state.list = [];
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        unsetActive: (state) => {
            state.active = null;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
        clearState: (state) => {
            state = initialState;
        },
    },
});

export const {
    setPagination,
    setList,
    clearPagination,
    clearList,
    setActive,
    unsetActive,
    clearState,
} = PlanningSheets.actions;

export default PlanningSheets.reducer;

export const startSavePlanningSheets = (
    values,
    setErrors = null,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let data = objectToFormData(values);
        data.delete("files");
        // data.delete("removed_files");
        // data.delete("is_visible");

        const formData = new FormData();

        if (values?.files) {
            values.files.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });
        }

        const resp = await CallWithFormData(
            `teachers/planning-sheet/create`,
            "POST",
            mergeFormData(data, formData)
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Avance programático creado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            callback(resp.success.id);
            dispatch(setActive(resp.succes));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startShowPlanningSheetsList = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const { teacherId, subjectId } = values;

        const params = new URLSearchParams();
        params.set("school_subject_id", subjectId);
        params.set("teacher_id", teacherId);
        params.set("asList", 1);

        const resp = await Call(
            `teachers/planning-sheet/show`,
            "GET",
            params.toString()
        );
        if (resp.success) {
            dispatch(setList(resp.success));
        }
        else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startShowPlanningSheets = ({
    page = 1,
    query = "",
    number_rows = 10,
    school_subject_id = null,
    teacher_id = null,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("school_subject_id", school_subject_id);
        params.set("teacher_id", teacher_id);
        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);

        const resp = await Call(
            `teachers/planning-sheet/show`,
            "GET",
            params.toString()
        );
        if (resp.success) {
            dispatch(setPagination(resp.success));
        }
        else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startEditPlanningSheets = (
    values,
    setErrors = null,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let data = objectToFormData(values);
        data.delete("files");

        const formData = new FormData();

        if (values.files) {
            values.files.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });
        }

        const resp = await CallWithFormData(
            `teachers/planning-sheet/edit/${values.id}`,
            "POST",
            mergeFormData(data, formData)
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Avance programático editado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            callback(resp.success.id);
            dispatch(setActive(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startDeletePlanningSheet = (
    id,
    setErrors = null,
    callback = () => {}
) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());

            const resp = await Call(
                `teachers/planning-sheet/delete/${id}`,
                "DELETE"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Avance programático eliminado.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
                callback();
            } else {
                errorHandler(resp, setErrors);
            }

            dispatch(stopUILoading());
        });
    };
};
