import { types } from "../../types";

const initialState = {
    signature: "",
    merchant_id: "",
    email: "",
    full_name: "",
    currency: "",
    tax: "",
    taxReturnBase: "",
    country_code: "",
    status: "",
    description: "",
    referenceCode: "",
    expiration_date: "",
};

export const payuReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.startSavePayu:
            return {
                ...state,
                ...action.payload,
            };
        case types.startStatusPaymentPayu:
            return {
                ...state,
                status: action.payload.status,
            };
        case types.startClearDataPayu:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
};
