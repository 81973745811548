import { CSidebarNavTitle } from "@coreui/react";
import React, { useEffect } from "react";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { IconApp } from "../../../IconApp";
import { NavLinkApp } from "../../../NavLinkApp";
import SidebarNavAccordion from "../../../SidebarNavAccordion";
import { useDispatch, useSelector } from "react-redux";
import { startUIShowModulesCount } from "../../../../redux/actions/administrador/ui/ui";

export const Modules_Administratives = () => {
    const dispatch = useDispatch();
    const { hasPermission } = useHasPermission();
    const { color_text_menu_accordion } = useSelector((state) => state.config);

    const canShowAccordion = hasPermission([
        "INCIDENTS_CAPTURE",
        "EVENTS",
        "EVENTS_ADMIN",
        "SHOW_MANUALS",
        "TICKETS_USERS",
        "AREA_DIRECTOR",
        "FORMATS_PUBLIC",
    ]);

    useEffect(() => {
        if (canShowAccordion) {
            dispatch(startUIShowModulesCount("Modules_Administratives"));
        }
    }, [canShowAccordion]);

    return (
        <div>
            {canShowAccordion && (
                <SidebarNavAccordion
                    title={"Administrativos"}
                    itemLevel={0}
                    color={color_text_menu_accordion}
                    iconLeftClassName="fa-solid fa-briefcase fa-xl"
                >
                    {hasPermission("INCIDENTS_CAPTURE") && (
                        <NavLinkApp
                            name="Capturar Incidencia"
                            to="/app/calendar/"
                            icon={
                                <IconApp iconClassName="fas fa-calendar-times c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission(["EVENTS", "EVENTS_ADMIN"]) && (
                        <NavLinkApp
                            name="Reservar espacios"
                            to="/app/eventos/"
                            icon={
                                <IconApp iconClassName="fas fa-calendar-alt c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("SHOW_MANUALS") && (
                        <NavLinkApp
                            name="Manuales"
                            to="/app/manuals/public"
                            icon={
                                <IconApp iconClassName="fas fa-book c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("TICKETS_USERS") && (
                        <NavLinkApp
                            name="Crear/Ver Ticket TI"
                            to="/app/soporte"
                            icon={
                                <IconApp iconClassName="fas fa-book c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("FORMATS_PUBLIC") && (
                        <NavLinkApp
                            name="Banco de documentos"
                            to="/app/formats/public"
                            icon={
                                <IconApp iconClassName="fas fa-file-alt c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("AREA_DIRECTOR") && (
                        <NavLinkApp
                            name="Director de área"
                            to="/app/area_director/list"
                            icon={
                                <IconApp iconClassName="fas fa-praying-hands c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                </SidebarNavAccordion>
            )}
        </div>
    );
};
