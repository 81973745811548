import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    showTeachers: {
        data: [],
        per_page: 5,
    },
    list: [],
    total: 0,
    active: JSON.parse(localStorage.getItem("payroll-teacher")) || null,
};

export const payrollTeacherReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.payrollTeacherShow:
            return {
                ...state,
                pagination: action.payload.pagination,
                total: action.payload.total,
            };
        case types.payrollTeacherSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.payrollTeacherUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.payrollTeacherShowWithOutPunches:
            return {
                ...state,
                showTeachers: action.payload.showTeachers,
            };

        default:
            return state;
    }
};
