import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { Call, CallWithFormDataFile, downloadBlob } from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";

const initialState = {
    report: {
        cycles: [],
        cycles_ids: [],
        academic_schedules_count: 0,
        total_hours_sum: 0,
        school_subjects_count: 0,
        report: [],
    },
};

export const subjectHoursReport = createSlice({
    name: "subjectHoursReport",
    initialState,
    reducers: {
        setReport: (state, action) => {
            state.report = action.payload;
        },
        restartReport: (state, action) => {
            state.report = initialState.report;
        },
    },
});

export const { setReport, restartReport } = subjectHoursReport.actions;

export default subjectHoursReport.reducer;

//*Actions

export const startGetReport = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const { cycles, coordination_id, exclude_empty } = values;

        const urlParams = new URLSearchParams();

        urlParams.set("cycles", `${cycles.year}-${cycles.sub_cycle}`);
        urlParams.set("exclude_empty", exclude_empty ? 1 : 0);
        urlParams.set("export", 0);
        if (coordination_id) {
            urlParams.set("coordination_id", coordination_id);
        }

        const resp = await Call(
            `teachers/v2/school_subjects/schedules/hours`,
            "GET",
            urlParams.toString()
        );

        if (resp.success) {
            dispatch(setReport(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startExportReport = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const { cycles, coordination_id, exclude_empty } = values;

        const urlParams = new URLSearchParams();

        urlParams.set("cycles", `${cycles.year}-${cycles.sub_cycle}`);
        urlParams.set("exclude_empty", exclude_empty ? 1 : 0);
        urlParams.set("export", 1);
        if (coordination_id) {
            urlParams.set("coordination_id", coordination_id);
        }

        const response = await CallWithFormDataFile(
            `teachers/v2/school_subjects/schedules/hours`,
            "GET",
            urlParams.toString()
        );
        downloadBlob(response, "Reporte de horas por materia");

        dispatch(stopUILoading());
    };
};
