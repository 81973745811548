import { createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormData,
    CallWithFormDataFile,
    cleanEmpty,
    downloadBlob,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import Swal from "sweetalert2";
import { formatMoney } from "../../../helpers/FormatString";
import { startShow } from "../users/PaymentScreen";
import { startRenew as startRenewStudent } from "../../actions/administrador/auth/authStudents";

const initialState = {
    invoicesPeople: [],
    massiveInvoice: [],
    pagination: {
        data: [],
        per_page: 10,
    },
    total: 0,
    count: 0,
};

export const studentInvoice = createSlice({
    name: "invoices",
    initialState,
    reducers: {
        setInvoicePeople: (state, actions) => {
            state.invoicesPeople = actions.payload;
        },
        setCleanInvoicePeople: (state, actions) => {
            state.invoicesPeople = [];
        },
        setMassiveInvoice: (state, actions) => {
            state.massiveInvoice = actions.payload;
        },
        setTotal: (state, actions) => {
            state.total = actions.payload;
        },
        setCount: (state, action) => {
            state.count = action.payload;
        },
        setInvoices: (state, action) => {
            state.pagination = action.payload;
        },
        clearState: (state) => {
            return initialState;
        },
    },
});

export const {
    setInvoicePeople,
    setCleanInvoicePeople,
    setMassiveInvoice,
    setTotal,
    setCount,
    setInvoices,
    clearState,
} = studentInvoice.actions;
export default studentInvoice.reducer;

export const getInvoiceOptions = () => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());
        const { user } = getState().auth;

        const params = new URLSearchParams();
        params.set("academic_record_id", user.id);

        const resp = await Call(`students/invoices`, "GET", params);
        if (resp.success) {
            dispatch(setInvoicePeople(resp.success));
        }

        dispatch(stopUILoading());
    };
};

export const downloadFile = (url, fileName) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        // await downloadBlob(url, "Factura-pdf.pdf");
        let link = document.createElement("a");

        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch(stopUILoading());
    };
};

export const cancelInvoice = (invoice_id, motive, note, currentValues) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("invoice_id", invoice_id);
        params.set("motive", motive);
        params.set("note", note);

        const resp = await Call(
            `services/invoice/cancel?${params.toString()}`,
            "DELETE"
        );
        if (resp.success) {
            Swal.fire({
                title: "Factura cancelada",
                text: "La factura ha sido cancelada",
                icon: "success",
                timer: 8000,
            });
            dispatch(startShow(currentValues));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const createInvoice = (
    payment_id,
    student_invoice_id,
    public_general,
    currentValues
) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        const { session } = getState().auth;
        let url = "";

        if (session === "operator") {
            url = "operators/invoices";
        } else {
            url = "operators/invoices/student";
        }

        const resp = await Call(url, "POST", {
            payment_id,
            student_invoice_id,
            public_general,
        });
        if (resp.success) {
            Swal.fire({
                title: "Factura generada",
                text: "La factura ha sido generada correctamente",
                icon: "success",
                timer: 8000,
            });
            dispatch(startShow(currentValues));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const sendInvoiceEmail = (invoiceId, email) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        const { session } = getState().auth;
        let url = "";

        if (session === "operator") {
            url = `operators/invoices/email/${invoiceId}`;
        } else {
            url = `students/invoices/email/${invoiceId}`;
        }

        const resp = await Call(url, "POST", { email });

        if (resp.success) {
            Swal.fire({
                title: "Factura enviada",
                text: "La factura ha sido enviada correctamente",
                icon: "success",
                timer: 8000,
            });
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

//* DATOS DE FACTURACION */

// Carga de datos de facturación por operadores
export const getDataInvoiceByStudent = (student_id) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `students/invoice_info/operator/${student_id}`,
            "GET"
        );
        if (resp.success) {
            dispatch(setInvoicePeople(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

//*Información de facturación de estudiante
export const startCreateInvoiceInfo = (
    values,
    setErrors,
    callback = () => {}
) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        let cleanedValues = cleanEmpty({ ...values });

        const { session } = getState().auth;
        let url = "";

        if (session === "operator") {
            url = "students/invoice_info/operator/create";
        } else {
            url = "students/invoice_info/student/create";
        }

        const response = await Call(url, "PUT", cleanedValues);

        if (response.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });

            if (session === "operator") {
                dispatch(getDataInvoiceByStudent(values.student_id));
            } else {
                dispatch(startRenewStudent());
            }

            callback(response.success);
        } else {
            errorHandler(response, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startUpdateInvoiceInfo = (values, setErrors) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        let cleanedValues = cleanEmpty({ ...values });

        const { session } = getState().auth;
        let url = "";

        if (session === "operator") {
            url = `students/invoice_info/operator/update/${values.id}`;
        } else {
            url = "students/invoice_info/student/update";
        }

        const response = await Call(url, "POST", cleanedValues);

        if (response.success) {
            Swal.fire({
                title: "Éxito",
                text: "Actualización exitosa.",
                icon: "success",
            });

            if (session === "operator") {
                dispatch(getDataInvoiceByStudent(values.student_id));
            } else {
                dispatch(startRenewStudent());
            }
        } else {
            errorHandler(response, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteInvoiceInfo = (
    invoiceInfoId,
    student_id,
    setErrors
) => {
    return async (dispatch, getState) => {
        const result = await Swal.fire({
            title: "¿Estás seguro de eliminar?",
            icon: "warning",
            text: "No podrás revertir esta acción!",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Si, Eliminar!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
        });
        if (result.value) {
            dispatch(startUILoading());

            const { session } = getState().auth;
            let url = "";

            if (session === "operator") {
                url = `students/invoice_info/operator/delete/${invoiceInfoId}`;
            } else {
                url = `students/invoice_info/student/delete/${invoiceInfoId}`;
            }

            const response = await Call(url, "DELETE");
            if (response.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Eliminación exitosa.",
                    icon: "success",
                });

                if (session === "operator") {
                    dispatch(getDataInvoiceByStudent(student_id));
                } else {
                    dispatch(startRenewStudent());
                }
            } else {
                errorHandler(response, setErrors);
            }
            dispatch(stopUILoading());
        }
    };
};

export const getMassiveInvoice = (
    from_,
    to_,
    payment_method,
    payable_type,
    account,
    level
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("from_", from_);
        params.set("to_", to_);
        params.set("payment_method", payment_method);
        params.set("payable_type", payable_type);
        if (account) {
            params.set("account", account);
        }

        params.set("level", level);

        const resp = await Call("operators/invoices", "GET", params);

        if (resp.success) {
            dispatch(setMassiveInvoice(resp.success.payments));
            dispatch(setTotal(resp.success.total));
            dispatch(setCount(resp.success.count));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const sendDataMassive = (
    payment_method,
    amount,
    concept,
    massive_ids,
    level,
    deduction
) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estás seguro que deseas genenerar la factura?",
            icon: "warning",
            text: `Monto: ${formatMoney(amount)}, Concepto: ${concept}`,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Si, facturar!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
        });
        if (result.value) {
            dispatch(startUILoading());
            const formData = new FormData();
            formData.set("payment_method", payment_method);
            formData.set("amount", amount);
            formData.set("concept", concept);
            formData.set("massive_ids", massive_ids);
            formData.set("level", level);
            if (deduction) {
                formData.set("deduction", deduction);
            }

            const resp = await CallWithFormData(
                "operators/invoices/massive",
                "POST",
                formData
            );

            if (resp.success) {
                dispatch(setMassiveInvoice([]));
                dispatch(setTotal(0));
                Swal.fire({
                    title: "Factura generada",
                    text: "La factura ha sido generada correctamente",
                    icon: "success",
                    timer: 8000,
                });
            } else {
                errorHandler(resp);
            }

            dispatch(stopUILoading());
        }
    };
};

export const startGetInvoices = (
    { page, query, number_rows, status, from, report, to },
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("page", page);
        params.set("q", query);
        params.set("number_rows", number_rows);
        params.set("status", status);
        params.set("from", from);
        params.set("to", to);

        const resp = await Call(
            "operators/invoices/get",
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setInvoices(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startGetExcel = ({
    page,
    query,
    number_rows,
    status,
    from,
    report,
    to,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("page", page);
        params.set("q", query);
        params.set("number_rows", number_rows);
        params.set("status", status);
        params.set("from", from);
        params.set("to", to);
        params.set("report", 1);

        const response = await CallWithFormDataFile(
            "operators/invoices/get",
            "GET",
            params.toString()
        );

        downloadBlob(response, "facturacion");
        dispatch(stopUILoading());
    };
};

export const handleManualInvoice = (values, setErrors, currentValues) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call("operators/invoices/manual", "POST", values);

        if (resp.success) {
            Swal.fire({
                title: "Factura generada",
                text: "La factura ha sido generada correctamente",
                icon: "success",
                timer: 8000,
            });
            dispatch(startGetInvoices(currentValues));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};
