import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { Call, CallWithFormDataFile } from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";

const initialState = {
    modal: false,
    pagination: {
        data: [],
        per_page: 10,
    },
};

export const schoolControls = createSlice({
    name: "schoolControls",
    initialState,
    reducers: {
        toggleModal: (state) => {
            state.modal = !state.modal;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const { toggleModal, setPagination } = schoolControls.actions;

export default schoolControls.reducer;

//*Actions

export const startGetSchoolControlReports = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const urlParams = new URLSearchParams();
        if (values?.query !== "") urlParams.set("q", values?.query);
        if (values?.number_rows) urlParams.set("per_page", values?.number_rows);
        if (values?.page) urlParams.set("page", values?.page);
        const resp = await Call(
            `users/school/control/reports`,
            "GET",
            urlParams.toString()
        );
        if (resp.success) {
            dispatch(setPagination(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startCreateReportByType = (values, onSuccess) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(
            `users/school/control/reports/create`,
            "PUT",
            values
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            onSuccess();
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startReportRigidCurriculum = (
    { certificate_number, certificate_date, academic_record },
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        params.set("certificate_number", certificate_number);
        params.set("certificate_date", certificate_date);
        const resp = await CallWithFormDataFile(
            `operators/reports/certificate/rigid_curriculum/${academic_record.id}`,
            "GET",
            params.toString()
        );
        if (resp.error) {
            errorHandler(resp);
        } else {
            let url = URL.createObjectURL(await resp);
            window.open(
                url,
                `Certificado`,
                "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=900, height=600"
            );
            callback();
        }
        // downloadBlob(resp,"Certificado rigida.pdf")
        dispatch(stopUILoading());
    };
};

export const startReportTotalCertificate = (
    { certificate_number, certificate_date, academic_record },
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        params.set("certificate_number", certificate_number);
        params.set("certificate_date", certificate_date);
        const resp = await CallWithFormDataFile(
            `operators/reports/certificate/total_certificate/${academic_record.id}`,
            "GET",
            params.toString()
        );
        if (resp.error) {
            errorHandler(resp);
        } else {
            let url = URL.createObjectURL(await resp);
            window.open(
                url,
                `Certificado`,
                "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=900, height=600"
            );
            callback();
        }
        // downloadBlob(resp,"Certificado rigida.pdf")
        dispatch(stopUILoading());
    };
};

export const startReportParcialCertificate = (
    { academic_record, certificate_number, certificate_date },
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        params.set("certificate_number", certificate_number);
        params.set("certificate_date", certificate_date);
        const resp = await CallWithFormDataFile(
            `operators/reports/certificate/parcial_certificate/${academic_record.id}`,
            "GET",
            params.toString()
        );
        if (resp.error) {
            errorHandler(resp);
        } else {
            let url = URL.createObjectURL(await resp);
            window.open(
                url,
                `Certificado`,
                "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=900, height=600"
            );
            callback();
        }
        // downloadBlob(resp,"Certificado rigida.pdf")
        dispatch(stopUILoading());
    };
};
