import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
    },
};

export const InscriptionSummarySlice = createSlice({
    name: "InscriptionSummarySlice",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
    },
});
export const { setPagination, clearPagination } =
    InscriptionSummarySlice.actions;

export default InscriptionSummarySlice.reducer;

// Actions
export const startGetReport = ({
    page = 1,
    query = "",
    number_rows = 50,
    cycle_id,
    country,
    start_date,
    end_date,
    curriculum_ids,
    exportFlag = 0,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let resp;
        const cycles = cycle_id.map((c) => c.value).join(",");

        if (exportFlag) {
            // Descargar Excel
            let formData = new FormData();
            formData.append("cycle_id", cycles);
            formData.append("export", exportFlag);
            formData.append("start_date", start_date);
            formData.append("end_date", end_date);
            formData.append("curriculum_ids", curriculum_ids);

            resp = await CallWithFormDataFile(
                "operators/reports/payments/inscription-summary",
                "POST",
                formData
            );
            downloadBlob(resp, "Resumen de inscripciones");
        } else {
            resp = await Call(
                `operators/reports/payments/inscription-summary?page=${page}&q=${query}&per_page=${number_rows}`,
                "POST",
                {
                    cycle_id: cycles,
                    country,
                    export: exportFlag,
                    start_date,
                    end_date,
                    curriculum_ids,
                }
            );
            if (resp.success) {
                dispatch(setPagination(resp.success));
            } else {
                errorHandler(resp);
            }
        }

        dispatch(stopUILoading());
    };
};

export const startClearReport = () => {
    return async (dispatch) => {
        dispatch(startUILoading());

        dispatch(clearPagination());

        dispatch(stopUILoading());
    };
};
