import { types } from "../../../types";

const initialState = {
    tiny_name: "",
    ...JSON.parse(root.getAttribute("configs")),

};

export const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.setConfig:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};
