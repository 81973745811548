import {
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLogout } from "../../redux/actions/administrador/auth/auth";
import { startLogoutTeacher } from "../../redux/actions/administrador/auth/authTeachers";
import { AvatarApp } from "../AvatarApp";
import { IconApp } from "../IconApp";

const TheHeaderDropdown = () => {
    const { user, session } = useSelector((state) => state.auth);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = async () => {
        let redirect = "/";

        switch (session) {
            case "teacher":
                await dispatch(startLogout());
                redirect = "/teachers/login";
                break;
            case "student":
                await dispatch(startLogout());
                redirect = "/students/login";
                break;
            default:
                await dispatch(startLogout());
                break;
        }

        window.location.replace(redirect);
    };

    const handleMyProfile = () => {
        navigate(`/app/profile`);
    };

    return (
        <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
            <CDropdownToggle className="c-header-nav-link" caret={false}>
                <label className="mx-2" id={user.id}>
                    {user.username}
                </label>
                <div className="c-avatar">
                    <AvatarApp
                        img={user.uri}
                        maxHeight={40}
                        textToGenerateAvatar={user.email}
                    />
                </div>
            </CDropdownToggle>
            <CDropdownMenu className="pt-0" placement="bottom-end">
                <CDropdownItem
                    header
                    tag="div"
                    color="light"
                    className="text-center"
                >
                    <strong>
                        Usuario{" "}
                        {/*session === "teacher" ? "docente" : "administrativo"*/}
                    </strong>
                </CDropdownItem>

                <CDropdownItem divider />
                <CDropdownItem onClick={handleMyProfile}>
                    <IconApp iconClassName="fas fa-user-alt mfe-2"></IconApp>
                    Ver mi perfil
                </CDropdownItem>
                <CDropdownItem onClick={handleLogout}>
                    <IconApp iconClassName="fas fa-sign-out-alt mfe-2"></IconApp>
                    Cerrar sesión
                </CDropdownItem>
            </CDropdownMenu>
        </CDropdown>
    );
};

export default TheHeaderDropdown;
