import { types } from "../../../types";

const initialState = {
    nom_name: "",
    data: [],
    pagination: [],
    openForm: false,
    openModalPunches: false,
    punchesByDay: {
        teacher: "",
        day: "",
        punches: [],
    },
};

export const payrollReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.payrollV2GetPunchesByDay:
            return {
                ...state,
                punchesByDay: action.payload
            };

        case types.payrollV2GetByTeacher:
            return {
                ...state,
                nom_name: action.payload.nom_name,
                data: action.payload.data,
                pagination: action.payload.pagination,
                total_general: action.payload.total_general,
                total_justify: action.payload.total_justify,
                total_classes: action.payload.total_classes,
                hours_check: action.payload.hours_check,
                hours_total: action.payload.hours_total,
                hours_justify: action.payload.hours_justify,
                hours_classes: action.payload.hours_classes,
                hours_ead: action.payload.hours_ead,
                total_ead: action.payload.total_ead,
                total_hours: action.payload.total_hours,
                justify_total_hours: action.payload.justify_total_hours,
            };

        case types.payrollV2OpenModal:
            return {
                ...state,
                openForm: true,
            };

        case types.payrollV2CloseModal:
            return {
                ...state,
                openForm: false,
            };

        case types.payrollV2OpenModalPunches:
            return {
                ...state,
                openModalPunches: true,
            };

        case types.payrollV2CloseModalPunches:
            return {
                ...state,
                openModalPunches: false,
                punchesByDay: initialState.punchesByDay,
            };

        case types.payrollV2Clear:
            return {
                ...state,
                ...initialState,
            };

        case types.payrollV2ShowJustifies:
            return {
                ...state,
                pagination: action.payload.pagination,
            };

        default:
            return state;
    }
};
