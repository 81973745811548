import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { useHasPermission } from "../../hooks/useHasPermission";
import { TheLayout } from "../containers";
import { SuspenceFallbackContainer } from "./SuspenceFallbackContainer";

export const PrivateRoute = ({
    component: Component,
    layout = true,
    permissions = null,
}) => {
    const { hasPermission, isLogged } = useHasPermission();

    const isAccesible = () => {
        if (permissions === null) {
            return true;
        }
        return hasPermission(permissions);
    };

    return isLogged ? (
        <>
            {isAccesible() ? (
                layout ? (
                    <TheLayout>
                        <Suspense fallback={<SuspenceFallbackContainer />}>
                            <Component />
                        </Suspense>
                    </TheLayout>
                ) : (
                    <Suspense fallback={<SuspenceFallbackContainer />}>
                        <Component />
                    </Suspense>
                )
            ) : (
                <Navigate to={"/app/home"} />
            )}
        </>
    ) : (
        <Navigate to={"/"} />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.object.isRequired,
    layout: PropTypes.bool.isRequired,
    permissions: PropTypes.any,
};
