import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
    },
    active: null,
    openModal: false,
    openCycleModal: false,
};

export const curriculaTabulatorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.curriculaTabulatorsShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.curriculaTabulatorsSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.curriculaTabulatorsUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.curriculaTabulatorsOpenModal:
            return {
                ...state,
                openModal: true,
            };
        case types.curriculaTabulatorsCloseModal:
            return {
                ...state,
                openModal: false,
            };
        case types.curriculaTabulatorsOpenCycleModal:
            return {
                ...state,
                openCycleModal: true,
            };
        case types.curriculaTabulatorsCloseCycleModal:
            return {
                ...state,
                openCycleModal: false,
            };
        default:
            return state;
    }
};
