import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 25,
    },
    // paginationStudents: {
    //     data: [],
    //     per_page: 25,
    // },
};

export const bbvaReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.startShowBBVA:
            return {
                ...state,
                pagination:  action.payload,
            };

        default:
            return state;
    }
};
