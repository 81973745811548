import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    active: JSON.parse(localStorage.getItem("teacher")) || null,
    openForm: false,
    openContract: false,
    list: [],
    groups: [],
    subjects: [],
    subjectActive: null
};

export const teacherReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.teacherShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.teacherList:
            return {
                ...state,
                list: action.payload,
            };
        case types.teacherOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.teacherCloseModal:
            return {
                ...state,
                openForm: false,
                active: null,
            };
        case types.teacherOpenContract:
            return {
                ...state,
                openContract: true,
            };
        case types.teacherCloseContract:
            return {
                ...state,
                openContract: false,
            };

        case types.teacherSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.teacherUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.teacherGroups:
            return {
                ...state,
                groups: action.payload,
            };
        case types.teacherSubjects:
            return {
                ...state,
                subjects: action.payload,
            };
        case types.teacherSubjectActive:
            return {
                ...state,
                subjectActive: action.payload,
            };
        default:
            return state;
    }
};
