import { createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import Swal from "sweetalert2";

const initialState = {
    general: {
        pagination: {
            data: [],
            per_page: 10,
            total: 0,
        },
        list: [],
    },
};

export const AveragesReports = createSlice({
    name: "AveragesReports",
    initialState,
    reducers: {
        setPaginationGeneral: (state, action) => {
            state.general.pagination = action.payload;
        },
        clearPaginationGeneral: (state) => {
            state.general.pagination = initialState.general.pagination;
        },
        seListGeneral: (state, action) => {
            state.general.list = action.payload;
        },
        clearState: () => {
            return initialState;
        },
    },
});
export const {
    setPaginationGeneral,
    clearPaginationGeneral,
    seListGeneral,
    clearState,
} = AveragesReports.actions;

export default AveragesReports.reducer;

// Actions
export const startShowGeneral = ({
    page = 1,
    query = "",
    number_rows = 10,
    asList = 0,
    curriculum_id,
    degree,
    status,
    update_avgs = 0,
}) => {
    return async (dispatch) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const params = new URLSearchParams();

        if (asList) {
            params.set("asList", asList);
        } else {
            if (update_avgs) {
                params.append("update_avgs", update_avgs);
            }
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
            if (curriculum_id) {
                params.set("curriculum_id", curriculum_id);
            }
            if (degree) {
                params.set("degree", degree);
            }
            if (status) {
                params.set("status", status);
            }
        }

        const resp = await Call(
            `operators/students/grades/general/averages`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setPaginationGeneral(resp.success));
            if (update_avgs) {
                Swal.fire({
                    title: "Éxito",
                    text: "Los promedios se han actualizado.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 5000,
                });
            }
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startExportGeneral = ({ curriculum_id, degree, status }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("export", 1);

        if (curriculum_id) {
            params.set("curriculum_id", curriculum_id);
        }
        if (degree) {
            params.set("degree", degree);
        }
        if (status) {
            params.set("status", status);
        }

        const response = await CallWithFormDataFile(
            `operators/students/grades/general/averages`,
            "GET",
            params.toString()
        );
        downloadBlob(response, "Reporte de promedios generales");

        dispatch(stopUILoading());
    };
};
