import {
    CBreadcrumbRouter,
    CHeader,
    CHeaderBrand,
    CHeaderNav,
    CHeaderNavItem,
    CLink,
    CSubheader,
    CToggler,
} from "@coreui/react";
import React from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    startUIToggleSidebar,
    startUIToggleSidebarMobile,
} from "../../redux/actions/administrador/ui/ui";
import { TheHeaderDropdown } from "./index";
import { IconApp } from "../IconApp";

const TheHeader = () => {
    const dispatch = useDispatch();

    const { sidebarShow } = useSelector((state) => state.ui);

    const toggleSidebar = () => dispatch(startUIToggleSidebar(sidebarShow));

    const toggleSidebarMobile = () =>
        dispatch(startUIToggleSidebarMobile(sidebarShow));

    const { color_nav, logo, tiny_name } = useSelector((state) => state.config);

    return (
        <CHeader
            withSubheader
            colorScheme="#221e1e"
            style={{
                backgroundColor: color_nav,
                border: "0px solid" + color_nav,
            }}
        >
            <CToggler
                inHeader
                className="ml-md-3 d-lg-none"
                onClick={toggleSidebarMobile}
                name="toggle-md"
            >
                <IconApp iconClassName="fa-solid fa-bars fa-2xl text-white"/>
            </CToggler>
            <CToggler
                inHeader
                className="ml-3 d-md-down-none header-toggler-color"
                onClick={toggleSidebar}
                name="toggle-lg"
            >
                <IconApp iconClassName="fa-solid fa-bars fa-2xl text-white"/>
            </CToggler>
            <CHeaderBrand className="mx-auto d-lg-none" to="/">
                <Image
                    className="animate__animated animate__backInLeft"
                    src={`/storage/${logo}`}
                    rounded
                    style={{
                        height: 40,
                        objectFit: "scale-down",
                        marginRight: 0,
                    }}
                    alt={tiny_name}
                />
            </CHeaderBrand>

            <CHeaderNav className="d-md-down-none mr-auto">
                {!sidebarShow && (
                    <CHeaderNavItem className="px-3">
                        <Image
                            className="animate__animated animate__backInLeft"
                            src={`/storage/${logo}`}
                            rounded
                            style={{
                                height: 40,
                                objectFit: "scale-down",
                                marginRight: 0,
                            }}
                            alt={tiny_name}
                        />
                    </CHeaderNavItem>
                )}
            </CHeaderNav>

            <CHeaderNav className="px-3 text-right">
                <TheHeaderDropdown />
            </CHeaderNav>

            {/* <CSubheader className="px-3 justify-content-between">

                <CBreadcrumbRouter
                    className="border-0 c-subheader-nav m-0 px-0 px-md-3"

                />
               <div className="d-md-down-none mfe-2 c-subheader-nav">
                    <CLink className="c-subheader-nav-link" href="#">
                       settings
                    </CLink>
                    <CLink
                        className="c-subheader-nav-link"
                        aria-current="page"
                        to="/dashboard"
                    >

                        &nbsp;Dashboard
                    </CLink>
                    <CLink className="c-subheader-nav-link" href="#">

                        &nbsp;Settings
                    </CLink>
                </div>
           </CSubheader> */}
        </CHeader>
    );
};

export default TheHeader;
