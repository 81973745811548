import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    active: JSON.parse(localStorage.getItem("payroll")) || null,
    openJustifyDayForm: false,
    openForm: false,
    list: [],
};

export const cutspayrollReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.cutspayrollList:
            return {
                ...state,
                list: action.payload,
            };
        case types.cutspayrollShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.cutspayrollOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.cutspayrollCloseModal:
            return {
                ...state,
                openForm: false,
            };
        default:
            return state;
    }
};
