import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
        total: 0,
    },
    list: [],
};

export const ExtracurricularSubjects = createSlice({
    name: "ExtracurricularSubjects",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
    },
});
export const { setPagination, setList, clearPagination } =
    ExtracurricularSubjects.actions;

export default ExtracurricularSubjects.reducer;

// Actions
export const startShowDistributionList = ({
    page = 1,
    query = "",
    number_rows = 6,
    asList = 0,
    users_type,
}) => {
    return async (dispatch) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const params = new URLSearchParams();

        if (asList) {
            params.set("asList", asList);
        } else {
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
            params.set("users_type", users_type);
        }

        const resp = await Call(
            `operators/distribution-list/show`,
            "GET",
            params
        );

        if (resp.success) {
            if (asList) {
                dispatch(setList(resp.success));
            } else {
                dispatch(setPagination(resp.success));
            }
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startSaveDistributionList = (values, paginationValues) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/distribution-list/create`,
            "PUT",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Lista de distribución creada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(startShowDistributionList(paginationValues));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startEditDistributionList = (
    distribution_list_id,
    values,
    paginationValues
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/distribution-list/edit/${distribution_list_id}`,
            "PUT",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Lista de distribución editada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(startShowDistributionList(paginationValues));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteDistributionList = (
    distribution_list_id,
    paginationValues
) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());

            const resp = await Call(
                `operators/distribution-list/delete/${distribution_list_id}`,
                "DELETE"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Recurso eliminado exitosamente.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
                dispatch(startShowDistributionList(paginationValues));
            } else {
                errorHandler(resp);
            }

            dispatch(stopUILoading());
        });
    };
};
