import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 10
    },
    active: null,
    list: [],
    openModal: false,
};



export const scheduleStudentReducer = (state = initialState, action) => {

    switch (action.type) {

        
        case types.scheduleSetActive:
            return {
                ...state,
                active: action.payload
            }

        case types.scheduleUnsetActive:
            return {
                ...state,
                active: null
            }



        default:
            return state;
    }
}
