import { types } from "../../../types";

const initialState = {
    openPunch: false,
    calendar: [],
    total_hours: 0,
};

export const punchesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.punchesOpenModal:
            return {
                ...state,
                openPunch: true
            };
        case types.punchesCloseModal:
            return {
                ...state,
                openPunch: false,
            };

        default:
            return state;
    }
};
