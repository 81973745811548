import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
    },
    active: null,
    list: [],
    list2: [],
    openModal: false,
    calendar: {
        min: 7,
        max: 21,
        week_schedule: [],
    },
};

export const classroomReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.classroomSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.classroomUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.classroomShow:
            return {
                ...state,
                pagination: action.payload,
            };
        case types.classroomList:
            return {
                ...state,
                list: action.payload,
            };
        case types.classroomList2:
            return {
                ...state,
                list2: action.payload,
            };
        case types.classroomClearList2:
            return {
                ...state,
                list2: initialState.list2,
            };
        case types.classroomOpenModal:
            return {
                ...state,
                openModal: true,
            };

        case types.classroomCloseModal:
            return {
                ...state,
                openModal: false,
            };
        case types.classroomSetCalendar:
            return {
                ...state,
                calendar: action.payload,
            };
        default:
            return state;
    }
};
