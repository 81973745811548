import { types } from "../../../types";

const initialState = {
    open: false,
    schedules: [],
    total_hours: 0,
    cycles: [],
    total_cost: 0,
    data_type_class: [],
};

export const scheduleTeachersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.schedulesTeacherShow:
             return {
                ...state,
                schedules: action.payload.schedule || [],
                total_hours: action.payload.total_hours,
                total_cost: action.payload.total_cost,
                cycles: action.payload?.cycles || [],
            };
        case types.schedulesTeacherOpenModal:
            return {
                ...state,
                open: true,
            };
        case types.schedulesTeacherCloseModal:
            return {
                ...state,
                open: false,
                schedule: [],
            };
        case types.schedulesTeacherClasses:
            return{
                ...state,
                data_type_class: action.payload
            }
        default:
            return state;
    }
};
