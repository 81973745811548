import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { Call } from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";

const initialState = {
    tabulators: null,
    pagination: {
        data: [],
    },
};

export const ChargeBySubjectSlice = createSlice({
    name: "ChargeBySubjectSlice",
    initialState,
    reducers: {
        setTabulatorsByStudent: (state, action) => {
            state.tabulators = action.payload;
        },
        setChargeBySubject: (state, action) => {
            state.pagination = action.payload;
        },
    },
});
export const { setTabulatorsByStudent, setChargeBySubject } =
    ChargeBySubjectSlice.actions;

export default ChargeBySubjectSlice.reducer;

// Actions
export const startGetTabulatorsByAcademicRecord = ({
    cycle,
    academic_record,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        params.append("cycle_id", cycle.id);
        params.append("academic_record_id", academic_record.id);

        const resp = await Call(
            `students/account-status/get/tabulators?${params.toString()}`,
            "GET"
        );

        if (resp.success) {
            dispatch(setTabulatorsByStudent(resp.success.data));
        }

        dispatch(stopUILoading());
    };
};

export const startCreate = (values, callback) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const { academic_record_id } = values;

        const resp = await Call(
            `operators/charge_by_subject/create`,
            "POST",
            values
        );

        if (resp.success) {
            // dispatch(setChargeBySubject(resp.success));
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Se regis con éxito.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startGet = ({
    query = "",
    page = 1,
    number_rows = 25,
    curricula = [],
    cycle_id = "",
    subjects_num,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        params.set("q", query);
        params.set("per_page", page);
        params.set("number_rows", number_rows);
        params.set("cycle_id", cycle_id);
        const curricula_ids = curricula.map((c) => c.value).join(",");
        params.set("curricula", curricula_ids);
        params.set("subjects_num", subjects_num);
        const resp = await Call(`operators/charge_by_subject`, "GET", params);
        if (resp.success) {
            dispatch(setChargeBySubject(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startEditSubject = (values, id, paginationData) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(
            `operators/charge_by_subject/${id}`,
            "POST",
            values
        );

        if (resp.success) {
            dispatch(startGet(paginationData));
            Swal.fire({
                title: "Éxito",
                text: "Se actualizó con éxito.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startDelete = (id, paginationData) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estas seguro que deseas eliminar el registro??",
            icon: "warning",
            text: "Una vez realizado el movimiento no podrás revertir los cambios",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "¡Si, eliminar ahora!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
        });
        if (result.isConfirmed) {
            dispatch(startUILoading());
            const resp = await Call(
                `operators/charge_by_subject/${id}`,
                "DELETE"
            );

            if (resp.success) {
                dispatch(startGet(paginationData));
                Swal.fire({
                    title: "Éxito",
                    text: "Se eliminó el recurso.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
            } else {
                errorHandler(resp);
            }
            dispatch(stopUILoading());
        }
    };
};
