import { CSidebarNavTitle } from "@coreui/react";
import React, { useEffect } from "react";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { IconApp } from "../../../IconApp";
import { NavLinkApp } from "../../../NavLinkApp";
import SidebarNavAccordion from "../../../SidebarNavAccordion";
import { useDispatch, useSelector } from "react-redux";
import { startUIShowModulesCount } from "../../../../redux/actions/administrador/ui/ui";

export const Modules_RH = () => {
    const dispatch = useDispatch();
    const { hasPermission } = useHasPermission();
    const { color_text_menu_accordion } = useSelector((state) => state.config);

    const canShowAccordion = hasPermission([
        "EMPLOYEES",
        "INCIDENTS",
        "REPORT_PAYROLL",
        "ORGANIZATION_CHART",
        "VACATIONS_CORPORATIVE",
    ]);

    useEffect(() => {
        if (canShowAccordion) {
            dispatch(startUIShowModulesCount("Modules_RH"));
        }
    }, [canShowAccordion]);
    return (
        <div>
            {canShowAccordion && (
                <SidebarNavAccordion
                    title={"Recursos Humanos"}
                    itemLevel={0}
                    iconLeftClassName="fa-regular fa-person fa-2xl"
                    color={color_text_menu_accordion}
                >
                    {hasPermission("EMPLOYEES") && (
                        <>
                            <NavLinkApp
                                name="Empleados"
                                to="/app/employees/list"
                                icon={
                                    <IconApp iconClassName="fas fa-users c-sidebar-nav-icon"></IconApp>
                                }
                            />
                            <NavLinkApp
                                name="Departamentos"
                                to="/app/department/list"
                                icon={
                                    <IconApp iconClassName="fas fa-briefcase c-sidebar-nav-icon"></IconApp>
                                }
                            />
                        </>
                    )}

                    {hasPermission("VACATIONS_CORPORATIVE") && (
                        <NavLinkApp
                            name="Vacaciones"
                            to="/app/vacations/list"
                            icon={
                                <IconApp iconClassName="fas fa-praying-hands c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission("ORGANIZATION_CHART") && (
                        <>
                            <NavLinkApp
                                name="Organigrama"
                                to="/app/employees/organization"
                                icon={
                                    <IconApp iconClassName="fas fa-sitemap c-sidebar-nav-icon"></IconApp>
                                }
                            />
                        </>
                    )}
                    {hasPermission("INCIDENTS") && (
                        <NavLinkApp
                            name="Incidencias"
                            to="/app/incidents/list"
                            icon={
                                <IconApp iconClassName="fas fa-exclamation c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission("REPORT_PAYROLL") && (
                        <NavLinkApp
                            name="Reportes de nómina"
                            to="/app/incidents/reports"
                            icon={
                                <IconApp iconClassName="fas fa-file-medical-alt c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                </SidebarNavAccordion>
            )}
        </div>
    );
};
