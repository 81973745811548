import { CContainer } from "@coreui/react";
import React from "react";
import { useSelector } from "react-redux";
import { FooterApp } from "../FooterApp";
import { TheHeader, TheSidebar } from "./index";

const TheLayout = ({ children }) => {
    const {
        color_cont,
        text_lat_hover,
        color_lat,
        color_nav,
        color_text_menu,
        text_lat,
        color_lat_hover,
        text_nav,
        text_cont,
    } = useSelector((state) => state.config);



    const css = `
    html,body{
        background-color: ${color_nav};
    }
    .c-default-layout{
        background-color: ${color_cont}
    }
    .c-sidebar-nav-link  {
        color:${color_text_menu} !important;
    }
    .c-sidebar-nav-icon{
        color:${color_text_menu} !important;
    }
    .c-sidebar-nav-title{
        color:${text_lat} !important;
    }
    .activeNavLinkApp{
        background: ${text_lat} !important;
    }
    .activeNavLinkApp a,activeNavLinkApp i{
        color: ${text_nav} !important;
    }
    .activeNavLinkApp i{
        color: ${text_nav} !important;
    }
    .c-sidebar-nav-link:hover{
        color:${text_lat_hover} !important;
    }
    .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon{
        color:${text_lat_hover} !important;
    }
    .c-sidebar .c-sidebar-nav-link:hover{
        background:${color_lat_hover} !important;
    }
    .c-header-nav-link label {
        color:${text_nav} !important;
    }
    label {
        color:${text_cont} !important;
    }`;

    return (
        <div className="c-app c-default-layout">
            <style>{css}</style>
            <TheSidebar />
            <div className="c-wrapper">
                <TheHeader />
                <div className="c-body">
                    <CContainer fluid className={"mt-3 mb-5"}>
                        {children}
                    </CContainer>
                </div>
            </div>
            <FooterApp />
        </div>
    );
};

export default TheLayout;
