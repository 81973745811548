import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5
    },
    active: null,
};

export const payrollTeacherDetailReducer = (state = initialState, action) => {
           switch (action.type) {
               case types.payrollTeacherDetailShow:
                   return {
                       ...state,
                       pagination: action.payload.pagination
                   };
               case types.payrollTeacherDetailSetActive:
                   return {
                       ...state,
                       active: action.payload,
                   };
               case types.payrollTeacherDetailUnsetActive:
                   return {
                       ...state,
                       active: null,
                    };

               default:
                   return state;
           }
       };
