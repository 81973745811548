import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    Call,
    CallWithFormData,
    CallWithFormDataFile,
    cleanEmpty,
    downloadBlob,
    objectToFormData,
} from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";

const initialState = {
    pagination: {
        data: [],
        per_page: 25,
    },
    paginationAcademicRecords: {
        data: [],
        per_page: 25,
    },
    conventions: [],
    active: null,
    modalOpen: false,
    modalOpenAcademicRecords: false,
};

export const conventionCreateByStudent = createSlice({
    name: "conventionCreateByStudent",
    initialState,
    reducers: {
        setConventionsList: (state, action) => {
            state.conventions = action.payload;
        },
        setConventions: (state, action) => {
            state.pagination = action.payload;
        },
        setConventionsAcademicRecords: (state, action) => {
            state.paginationAcademicRecords = action.payload;
        },
        setConventionsListAcademicRecords: (state, action) => {
            state.listAcademicRecords = action.payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        unsetActive: (state) => {
            state.active = null;
        },
        setOpenModal: (state) => {
            state.modalOpen = true;
        },
        setCloseModal: (state) => {
            state.modalOpen = false;
        },
        setOpenModalAcademicRecords: (state) => {
            state.modalOpenAcademicRecords = true;
        },
        setCloseModalAcademicRecords: (state) => {
            state.modalOpenAcademicRecords = false;
        },
    },
});

export const {
    setConventions,
    setConventionsAcademicRecords,
    setConventionsListAcademicRecords,
    setConventionsList,
    setActive,
    setOpenModal,
    setCloseModal,
    setOpenModalAcademicRecords,
    setCloseModalAcademicRecords,
    unsetActive,
} = conventionCreateByStudent.actions;

export default conventionCreateByStudent.reducer;

// Actions
export const startCreateConventionById = (values, setErrors, callback) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const formData = new FormData();

        formData.append("convention_id", values.convention_id);
        formData.append("academic_record_id", values.academic_record_id);
        formData.append("cycle_id", values.cycle_id);
        formData.append("status", values.status);
        formData.append("document", values.document);

        const resp = await CallWithFormData(
            `operators/convention`,
            "POST",
            formData
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startGetConventions = () => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`operators/convention`, "GET");

        if (resp.success) {
            dispatch(setConventions(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startGetConventionsList = () => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("all", true);

        const resp = await Call(
            `operators/convention/get`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setConventionsList(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startCreateConventions = (values, currentValues, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(`operators/convention/create`, "POSt", values);

        if (resp.success) {
            Swal.fire({
                icon: "success",
                title: "Éxito",
                text: "Convenio registrado",
            });
            dispatch(startGetConventionsGet(currentValues));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startGetConventionsGet = ({
    page = 1,
    query = "",
    number_rows = 10,
    all = "",
}) => {
    return async (dispatch, getState) => {
        let { conventions } = getState().conventions;
        if (conventions.length > 0 && all) {
            return;
        }
        dispatch(startUILoading());
        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);
        params.set("all", all);

        const resp = await Call(
            `operators/convention/get`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (!all) {
                dispatch(setConventions(resp.success));
            } else {
                dispatch(setConventionsList(resp.success));
            }
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startGetConventionsAcademicRecords = ({
    page = 1,
    query = "",
    number_rows = 10,
    all = "",
    convention = "",
    cycle = "",
    status = "",
    statusStudent = [],
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);
        params.set("all", all);

        params.set("convention", convention);
        params.set("cycle_id", cycle);
        params.set("status", status);

        if (statusStudent.length > 0) {
            const joinStatusStudent = statusStudent
                ?.map((ss) => ss?.value)
                ?.join(",");
            params.set("statusStudent", joinStatusStudent);
        }

        const resp = await Call(
            `operators/convention/get/academic-records`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (resp.success.data) {
                dispatch(setConventionsAcademicRecords(resp.success));
            } else {
                dispatch(setConventionsListAcademicRecords(resp.success));
            }
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startUpdateConvention = (values, currentValues) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/convention/edit/${values.id}`,
            "PUT",
            values
        );
        if (resp.success) {
            Swal.fire({
                icon: "success",
                title: "Exito",
                text: "Se actualizó el recurso.",
            });
            dispatch(startGetConventionsGet(currentValues));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};
export const startUpdateConventionAcademicRecords = (
    values,
    currentValues,
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/convention/edit/academic-records/${values.id}`,
            "PUT",
            values
        );
        if (resp.success) {
            Swal.fire({
                icon: "success",
                title: "Exito",
                text: "Se actualizó el recurso.",
            });
            dispatch(startGetConventionsAcademicRecords(currentValues));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteConvention = (id, values) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estás seguro de eliminar?",
            icon: "warning",
            text: "No podrás revertir esta acción!",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Si, Eliminar!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            input: "text",
            inputLabel:
                "Escribe la palabra 'eliminar', para confirmar esta operación.",
            inputAttributes: {
                autocapitalize: "off",
            },
        });

        if (result.value === "eliminar") {
            dispatch(startUILoading());

            const resp = await Call(
                `operators/convention/delete/${id}`,
                "DELETE"
            );

            if (resp.success) {
                dispatch(startGetConventionsGet(values));
                Swal.fire({
                    icon: "success",
                    title: "Se eliminó",
                });
            } else {
            }

            dispatch(stopUILoading());
        }
    };
};

export const startExportShow = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();

        params.set("export", 1);

        if (values.status !== "") {
            params.set("status", values.status);
        }
        if (values.cycle !== "") {
            params.set("cycle_id", values.cycle);
        }
        if (values.convention !== "") {
            params.set("convention", values.convention);
        }

        const response = await CallWithFormDataFile(
            `operators/convention/get/academic-records`,
            "GET",
            params.toString()
        );
        downloadBlob(response, "Reporte de convenios");

        dispatch(stopUILoading());
    };
};

export const startOpenModal = () => {
    return async (dispatch) => {
        dispatch(setOpenModal());
    };
};

export const startCloseModal = () => {
    return async (dispatch) => {
        dispatch(setCloseModal());
    };
};

export const startOpenModalAcademicRecords = () => {
    return async (dispatch) => {
        dispatch(setOpenModalAcademicRecords());
    };
};

export const startCloseModalAcademicRecords = () => {
    return async (dispatch) => {
        dispatch(setCloseModalAcademicRecords());
    };
};

export const startSetActive = (data) => {
    return async (dispatch) => {
        dispatch(setActive(data));
    };
};

export const startUnsetActive = () => {
    return async (dispatch) => {
        dispatch(unsetActive());
    };
};
