import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    active: null,
    openForm: false,
    openAddTeacher: false,
};

export const justificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.justificationOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.justificationCloseModal:
            return {
                ...state,
                openForm: false,
                active: null,
            };

        case types.justificationOpenAddTeacher:
            return {
                ...state,
                openAddTeacher: true,
            };
        case types.justificationCloseAddTeacher:
            return {
                ...state,
                openAddTeacher: false,
                active: null,
            };
        default:
            return state;
    }
};
