import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  minimized: false,
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleMinimize: (state) => {
      state.minimized = !state.minimized;
    },
  },
})

export const { toggleMinimize } = sidebarSlice.actions

export default sidebarSlice.reducer