import PropTypes from "prop-types";
import React from "react";
import { Link, useLocation } from "react-router-dom";

export const NavLinkApp = ({
    name = "",
    to = "",
    icon = "",
}) => {
    const location = useLocation();

    const isActiveRoute = () => {
        //se toman los dos primeros slash /, para hacer el match en las rutas

        if (to === location.pathname) {
            return true;
        }

        // let to_split = to.split("/");
        // let __to = `${to_split[0]}/${to_split[1]}/${to_split[2]}`;

        // let location_split = location.pathname.split("/");
        // let __location = `${location_split[0]}/${location_split[1]}/${location_split[2]}`;

        // return __location === __to;
    };

    return (
        <li
            className={`c-sidebar-nav-item  ${
                isActiveRoute() ? "activeNavLinkApp" : ""
            } `}
        >
            <Link className="c-sidebar-nav-link" to={to}>
                {icon}
                {name}
            </Link>
        </li>
    );
};

NavLinkApp.propTypes = {
    name: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    icon: PropTypes.object,
};
