import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    openForm: false,
    openDetail: false,
    //    active: JSON.parse(localStorage.getItem("ticket-support-ti")) || null,
    active: null,
    user: "",
    messages: [],
};

export const supportTIReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.showTicketTI:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.openDetailTicketTI:
            return {
                ...state,
                openDetail: true,
            };
        case types.closeDetailTicketTI:
            return {
                ...state,
                openDetail: false,
                active: null,
            };

        case types.openFormTicketTI:
            return {
                ...state,
                openForm: true,
            };
        case types.closeFormTicketTI:
            return {
                ...state,
                openForm: false,
                active: null,
            };
        case types.setActiveTicketTI:
            return {
                ...state,
                active: action.payload,
            };
        case types.unsetActiveTicketTI:
            return {
                ...state,
                active: null,
            };
        case types.reloadChatTicketTI:
            return {
                ...state,
                messages: action.payload,
            };
        case types.clearChatTI:
            return {
                ...state,
                messages: [],
            };
        default:
            return state;
    }
};
