import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    toPay: [],
    payment_type: "",
};

export const shoppingCartApp = createSlice({
    name: "shoppingCartApp",
    initialState,
    reducers: {
        setToPay: (state, action) => {
            state.toPay = action.payload;
        },
        setPaymentType: (state, action) => {
            state.payment_type = action.payload;
        },
    },
});

export const { setToPay, setPaymentType } = shoppingCartApp.actions;
export default shoppingCartApp.reducer;