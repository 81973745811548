import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5
    },
    active: JSON.parse(localStorage.getItem("payroll")) || null,
    openJustifyDayForm:  false ,
    openForm:  false ,
};

export const payrollReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.payrollShow:
            return {
                ...state,
                pagination: action.payload.pagination
            };
        case types.payrollSetActive:
            return {
                ...state,
                active: action.payload
            };
        case types.payrollUnsetActive:
            return {
                ...state,
                active: null
            };
        case types.payrollOpenModal:
            return {
                ...state,
                openForm: true
            };
        case types.payrollCloseModal:
            return {
                ...state,
                openForm: false
            };
        case types.payrollJustifyDayOpenModal:
            return {
                ...state,
                openJustifyDayForm: true
            };
        case types.payrollJustifyDayCloseModal:
            return {
                ...state,
                openJustifyDayForm: false
            };

        default:
            return state;
    }
};
