import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { Call, CallWithFormDataFile } from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";

const initialState = {
    list: [],
    active: null,
    pagination: {
        data: [],
        per_page: 10,
    },
};

export const SchoolOfOrigins = createSlice({
    name: "SchoolOfOrigins",
    initialState,
    reducers: {
        setList: (state, action) => {
            state.list = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        unsetActive: (state, action) => {
            state.active = null;
        },
        addNewSchool: (state, action) => {
            return {
                ...state,
                list: [...state.list, action.payload],
            };
        },
    },
});

export const { setList, setActive, unsetActive, setPagination, addNewSchool } =
    SchoolOfOrigins.actions;

export default SchoolOfOrigins.reducer;

//*Actions

export const startShow = ({
    page = 1,
    query = "",
    number_rows = 50,
    all = false,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);
        if (all) {
            params.set("all", all);
        }

        const resp = await Call(
            `operators/school_origin/`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (all) {
                dispatch(setList(resp.success));
            } else {
                dispatch(setPagination(resp.success));
            }
        }
        dispatch(stopUILoading());
    };
};

export const startCreate = (values, currentValues, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call("operators/school_origin", "PUT", values);

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso",
                icon: "success",
            });
            dispatch(setActive(resp.success));
            dispatch(startShow(currentValues));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startEdit = (
    school_origin_id,
    values,
    currentValues,
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(
            `operators/school_origin/${school_origin_id}`,
            "POST",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Modificación exitosa",
                icon: "success",
            });
            dispatch(setActive(resp.success));
            dispatch(startShow(currentValues));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startDelete = (school_origin_id, currentValues) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());
            const resp = await Call(
                `operators/school_origin/${school_origin_id}`,
                "DELETE"
            );
            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Recurso eliminado exitosamente.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
                dispatch(startShow(currentValues));
            } else {
                errorHandler(resp);
            }

            dispatch(stopUILoading());
        });
    };
};

export const startAddSchool = (data, resetAndCloseModal, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`operators/school_origin`, "PUT", data);
        if (resp.success) {
            dispatch(addNewSchool(resp.success));
            resetAndCloseModal(resp.success.id);
            Swal.fire({
                title: "Éxito",
                text: "Escuela registrada exitosamente.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};
