import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stopUILoading } from "../redux/actions/administrador/ui/ui";

export const LoadingApp = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.ui);
    const [count, setCount] = useState(0);

    const countClicks = () => {
        setCount((c) => c + 1);
        if (count >= 5) {
            dispatch(stopUILoading());
            setCount(0);
        }
    };

    return (
        loading && (
            <div
                className="loading animate__animated animate__fadeIn"
                onClick={countClicks}
            ></div>
        )
    );
};
