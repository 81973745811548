import { types } from "../../../types";

const initialState = {
    nom_name: "",
    data: [],
    pagination: {
        data: [],
        per_page: 5,
    },
    active: null,
    openForm: false,
    openFormMassive: false,
};

export const justifyReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.justifyOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.justifyCloseModal:
            return {
                ...state,
                openForm: false,
                active: null,
            };
        case types.justifyOpenModalMassive:
            return {
                ...state,
                openFormMassive: true,
            };
        case types.justifyCloseModalMassive:
            return {
                ...state,
                openFormMassive: false,
                active: null,
            };

            case types.justifySetActive:
                return {
                    ...state,
                    active: action.payload,
                };

        case types.justifyShowJustifies:
            return {
                ...state,
                pagination: action.payload.pagination,
            };

        default:
            return state;
    }
};
