import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { Call } from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";
import { startRenew } from "../../actions/administrador/auth/auth";
import { startStudentSetActive } from "../../actions/students/students";
import { cloneWith } from "lodash";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
    },
    servicesByStudent: [],
    list: [],
    showModal: false,
    active: null,
};

export const scholarServices = createSlice({
    name: "scholarServices",
    initialState,
    reducers: {
        setServicesByStudent: (state, action) => {
            state.servicesByStudent = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        setSetActive: (state, action) => {
            state.active = action.payload;
        },
        startUnsetActive: (state) => {
            state.active = null;
        },
        openModal: (state) => {
            state.showModal = true;
        },
        closeModal: (state) => {
            state.showModal = false;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const {
    setList,
    setSetActive,
    startUnsetActive,
    openModal,
    closeModal,
    setServicesByStudent,
    setPagination,
} = scholarServices.actions;
export default scholarServices.reducer;

// Actions

export const startGetAllServices = (values, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(`students/services/all`);

        if (resp.success) {
            dispatch(setList(resp.success));
        } else {
            // errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startGetServicesByStudentWithToken = () => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(`students/services/by/student`, "GET");
        if (resp.success) {
            dispatch(setServicesByStudent(resp?.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startGetServicesByStudentById = (id) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(`students/services/by/student/${id}`, "GET");
        if (resp.success) {
            dispatch(setServicesByStudent(resp?.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startGetServicesByStudent = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        
        params.set("page", values.page);
        params.set("per_page", values.number_rows);
        if (values?.cycle_id) {
            params.set("cycle_id", values.cycle_id);
        }
        
        const resp = await Call(
            `students/services/by/${values.academic_record}`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setPagination(resp?.success?.data));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startDeleteServicesByStudent = (
    id,
    academic_record,
    paginationScholarServices,
    setErrors
) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estás seguro de eliminar?",
            icon: "warning",
            text: "No podrás revertir esta acción!",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Si, Eliminar!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            input: "text",
            inputLabel:
                "Escribe la palabra 'eliminar', para confirmar esta operación.",
            inputAttributes: {
                autocapitalize: "off",
            },
        });
        if (result.value === "eliminar") {
            dispatch(startUILoading());
            const resp = await Call(
                `students/services/delete/by/${id}`,
                "DELETE"
            );
            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Eliminación exitosa.",
                    icon: "success",
                });
                dispatch(
                    startGetServicesByStudent({ ...paginationScholarServices })
                );
                academic_record.positive_balance.balance =
                    academic_record.positive_balance.balance +
                    resp.success.take_positive_balance;
                dispatch(
                    startStudentSetActive({
                        ...academic_record,
                    })
                );
            } else {
                errorHandler(resp);
            }
            dispatch(stopUILoading());
        }
    };
};

export const startCondonateSurcharges = (
    academicService,
    getData = () => {}
) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "Condonación de recargos",
            icon: "warning",
            text: "Esta acción habilita o deshabilita los recargos sobre este servicio",
            showCancelButton: true,
            cancelButtonText: "Habilitar recargos",
            confirmButtonText: "Condonar recargos",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
        });

        const has_surcharges = result.isDismissed;

        dispatch(startUILoading());
        const resp = await Call(
            `students/services/has_surcharges/${academicService.id}`,
            "PUT",
            { has_surcharges }
        );

        if (resp.success) {
            getData();
        }
        dispatch(stopUILoading());
    };
};

export const startCreate = (
    values,
    academic_record,
    currentValues,
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = {
            ...values,
            delete_on_expire: values.services,
            delete_on_expire: values.expiration_action == 2,
            generate_surcharges: values.expiration_action == 1,
        };
        const resp = await Call(`students/services/create`, "POST", params);
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
            dispatch(startGetServicesByStudent(currentValues));

            if (academic_record.positive_balance) {
                academic_record.positive_balance.balance =
                    academic_record.positive_balance.balance -
                    values.take_positive_balance;
                // dispatch(
                //     startStudentSetActive({
                //         ...academic_record,
                //         positive_balance: {
                //             ...academic_record.positive_balance,
                //             balance:
                //                 academic_record.positive_balance.balance - values.take_positive_balance,
                //         },
                //     })
                // );
            }
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startEdit = (
    service_id,
    academic_record,
    values,
    currentValues,
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = {
            ...values,
            delete_on_expire: values.expiration_action == 2,
            generate_surcharges: values.expiration_action == 1,
        };

        const resp = await Call(
            `students/services/edit/${service_id}`,
            "POST",
            params
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro actualizado con exito.",
                icon: "success",
            });
            dispatch(startGetServicesByStudent(currentValues));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startShowMigrationKey = (migrationKey) => {
    return async (dispatch) => {
        Swal.fire({
            title: "Llave de migración:",
            text: migrationKey,
            icon: "info",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        });
    };
};
