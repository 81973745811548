import { types } from "../../../types";

const initialState = {
    openForm: false,
    pagination: {
        data: [],
        per_page: 5,
    },
    active: JSON.parse(localStorage.getItem("active")) || null,
};

export const tabulatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.tabulatorShow:
            return {
                ...state,
                pagination: action.payload,
            };
        case types.tabulatorOpen:
            return {
                ...state,
                openForm: true,
            };
        case types.tabulatorClose:
            return {
                ...state,
                openForm: false,
            };
        case types.tabulatorSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.tabulatorUnsetActive:
            return {
                ...state,
                active: null,
            };

        default:
            return state;
    }
};
