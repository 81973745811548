import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
    },
    active: null,
    list: [],
    classSchedulesReport: null,
    openModal: false,
};

export const buildingReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.buildingSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.buildingUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.buildingShow:
            return {
                ...state,
                pagination: action.payload,
            };
        case types.buildingList:
            return {
                ...state,
                list: action.payload,
            };
        case types.buildingOpenModal:
            return {
                ...state,
                openModal: true,
            };

        case types.buildingCloseModal:
            return {
                ...state,
                openModal: false,
            };
        case types.buildingSetClassSchedulesReport:
            return {
                ...state,
                classSchedulesReport: action.payload,
            };
        default:
            return state;
    }
};
