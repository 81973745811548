import { types } from "../../types";

const initialState = {
    event_day: null,
    active: {
        title: "",
        observation: "",
        user: {
            employee: {
                username: "",
             },
        },
    },
    openEvent: false,
    openForm: null,
    list: [],
    room: null,
    pagination: {
        data: [],
        per_page: 10,
    },
};

export const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.eventsShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.eventsGet:
            return {
                ...state,
                list: action.payload,
            };


        case types.eventsOpenForm:
            return {
                ...state,
                openForm: true,
            };
        case types.eventsCloseForm:
            return {
                ...state,
                openForm: false,
                event_day: null,
            };
        case types.eventsSetRoom:
            return {
                ...state,
                room: action.payload,
            };

        case types.eventsOpenEvent:
            return {
                ...state,
                openEvent: true,
                active: action.payload,
            };
        case types.eventsCloseEvent:
            return {
                ...state,
                openEvent: false,
                active: initialState.active,
            };

        default:
            return state;
    }
};
