import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    Call,
    CallWithFormData,
    cleanEmpty,
    objectToFormData,
} from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";

const initialState = {
    pagination: {
        data: [],
        per_page: 25,
    },
    modalOpen: false,
    active: null,
    list: [],
};

export const discounts = createSlice({
    name: "discounts",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        unsetActive: (state) => {
            state.active = null;
        },
        setOpenModal: (state) => {
            state.modalOpen = true;
        },
        setCloseModal: (state) => {
            state.modalOpen = false;
            state.active = null;
        },
    },
});

export const {
    setActive,
    setOpenModal,
    setCloseModal,
    unsetActive,
    setPagination,
    setList,
} = discounts.actions;

export default discounts.reducer;

//* Actions
export const startCreateDiscountById = (values, setErrors, callback) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const formData = new FormData();

        formData.append("academic_record_id", values.academic_record_id);
        formData.append("amount", values.amount);
        formData.append("cycle_id", values.cycle_id);
        formData.append("document", values.document);
        formData.append("motive", values.motive);
        formData.append("status", values.status);

        const resp = await CallWithFormData(
            `students/account-status/discount`,
            "POST",
            formData
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startCreatePropedeuticById = (values, setErrors, callback) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const formData = new FormData();

        formData.append("academic_record_id", values.academic_record_id);
        formData.append("amount", values.amount);
        formData.append("cycle_id", values.cycle_id);

        const resp = await CallWithFormData(
            `students/account-status/propedeutic`,
            "POST",
            formData
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startGetDiscounts = ({
    page = 1,
    query = "",
    number_rows = 10,
    all = "",
    amount = "",
    cycle = "",
    motive = "",
    status = "",
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("amount", amount);
        params.set("page", page);
        params.set("cycle", cycle);
        params.set("motive", motive);
        params.set("status", status);
        params.set("q", query);
        params.set("per_page", number_rows);
        params.set("all", all);

        const resp = await Call(`operators/discount`, "GET", params.toString());

        if (resp.success) {
            if (resp.success.data) {
                dispatch(setPagination(resp.success));
            } else {
                dispatch(setList(resp.success));
            }
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startUpdateDiscounts = (values, currentValues, setErrors, callback = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/discount/edit/${values.id}`,
            "PUT",
            values
        );
        if (resp.success) {
            Swal.fire({
                icon: "success",
                title: "Exito",
                text: "Se actualizó el recurso.",
            });

            if (currentValues) {
                dispatch(startGetDiscounts(currentValues));
            }

            callback();
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startOpenModal = () => {
    return async (dispatch) => {
        dispatch(setOpenModal());
    };
};

export const startCloseModal = () => {
    return async (dispatch) => {
        dispatch(setCloseModal());
    };
};

export const startSetActive = (data) => {
    return async (dispatch) => {
        dispatch(setActive(data));
    };
};

export const startUnsetActive = () => {
    return async (dispatch) => {
        dispatch(unsetActive());
    };
};
