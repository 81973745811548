import { types } from "../../../types";

export const startUILoading = () => ({
    type: types.uiStartLoading,
});

export const stopUILoading = () => ({
    type: types.uiStopLoading,
});

export const startUIToggleSidebar = (sidebarShow) => ({
    type: types.uiSidebar,
    payload: [true, "responsive"].includes(sidebarShow) ? false : "responsive",
});

export const startUIShowModulesCount = (moduleGroup) => {
    return async (dispatch, getState) => {
        const { uiShowModulesCount } = getState().ui;

        const isShowed = uiShowModulesCount.includes(moduleGroup);
        if (isShowed) return;

        const modules = [...uiShowModulesCount, moduleGroup];
        dispatch(setUIShowModulesCount(modules));
    };
};

export const setUIShowModulesCount = (modules) => ({
    type: types.uiIncrementShowModulesCount,
    payload: modules,
});

export const startUIShowNotification = (notification) => ({
    type: types.uiShownotification,
    payload: {
        notification,
    },
});

export const startUISetWatchedNotification = (notify) => {
    return async (dispatch, getState) => {
        const { notifications } = getState().ui;

        const notifies = notifications.map((n) => {
            if (n.id === notify.id) {
                n.watched = true;
                n.show = false;
            }
            return n;
        });

        dispatch(setNotifications(notifies));
    };
};

export const startUIToggleSidebarMobile = (sidebarShow) => {
    const val = [false, "responsive"].includes(sidebarShow)
        ? true
        : "responsive";
    return {
        type: types.uiSidebar,
        payload: val,
    };
};

export const uiSetEnviromentVersions = (module) => ({
    type: types.uiSetEnviromentVersions,
});

const setNotifications = (notifications) => ({
    type: types.uiSetNotifications,
    payload: notifications,
});
