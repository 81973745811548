import { createSlice } from "@reduxjs/toolkit";
import { Call } from "../../../../helpers/fetch";
import { errorHandler } from "../../../../helpers/errorHandler";
import { startUILoading, stopUILoading } from "../ui/ui";
import Swal from "sweetalert2";
import { deleteConfirmModal } from "../../../../helpers/DeleteValidate";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
        total: 0,
    },
    list: [],
};

export const Enterprises = createSlice({
    name: "Enterprises",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
    },
});
export const { setPagination, setList, clearPagination } = Enterprises.actions;

export default Enterprises.reducer;

// Actions
export const startShowEnterprises = ({
    page = 1,
    query = "",
    number_rows = 6,
    asList = 0,
}) => {
    return async (dispatch) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const params = new URLSearchParams();

        if (asList) {
            params.set("list", asList);
        } else {
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
        }

        const resp = await Call(`users/enterprise/show`, "GET", params);

        if (resp.success) {
            if (asList) {
                dispatch(setList(resp.success));
            } else {
                dispatch(setPagination(resp.success));
            }
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startSaveEnterprises = (values, paginationValues) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`users/enterprise/create`, "PUT", values);

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Empresa registrada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(startShowEnterprises(paginationValues));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startEditEnterprises = (
    enterprise_id,
    values,
    paginationValues
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `users/enterprise/edit/${enterprise_id}`,
            "POST",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Empresa editada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(startShowEnterprises(paginationValues));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteEnterprises = (enterprise_id, paginationValues) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());

            const resp = await Call(
                `users/enterprise/delete/${enterprise_id}`,
                "DELETE"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Empresa eliminada exitosamente.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
                dispatch(startShowEnterprises(paginationValues));
            } else {
                errorHandler(resp);
            }

            dispatch(stopUILoading());
        });
    };
};
