import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    active: null,
    list: [],
    cyclesByUser: [],
    openForm: false,
};

export const cyclesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CycleSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.CycleUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.CycleOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.CycleCloseModal:
            return {
                ...state,
                openForm: false,
            };
        case types.CyclesShow:
            return {
                ...state,
                pagination: action.payload.data,
            };
        case types.CyclesList:
            return {
                ...state,
                list: action.payload,
            };
        case types.CyclesByUsers:
            return {
                ...state,
                cyclesByUser: action.payload,
            };
        default:
            return state;
    }
};
