import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {
    getMessaging,
    getToken,
    onMessage,
    isSupported,
} from "firebase/messaging";
import { Call } from "./fetch";

const firebaseConfig = {
    apiKey: "AIzaSyCA7-sbgR9IUZOqNchSTJWRPZ74KlW_dOE",
    authDomain: "intranet-ucg.firebaseapp.com",
    projectId: "intranet-ucg",
    storageBucket: "intranet-ucg.appspot.com",
    messagingSenderId: "904637427607",
    appId: "1:904637427607:web:eda397d289aae0f5f25ed2",
    measurementId: "G-FT5QJE47X8",
};

// const analytics = getAnalytics(app);

const startFirebaseApp = async (callbackNotification) => {
    const isSupportedBrowser = await isSupported();

    if (!isSupportedBrowser) {
        console.warn("Firebase no disponible");
    }

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    const token = await getToken(messaging).catch((e) =>{
        console.log(`${e} , FMC`)
   } );

    if (token) {
        await Call(`users/notify/add/token`, "PUT", {
            token,
        });
    }
    onMessage(messaging, (payload) => {
        callbackNotification(payload);
    });
};

export { startFirebaseApp };
