import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5
    },
    list: [],
    next_number_employee: null,
    active: JSON.parse(localStorage.getItem("user")) || null,
    openForm: false,
    openFormModule: false
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.userShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.userGetNextNumberEmployee:
            return {
                ...state,
                next_number_employee: action.payload.next_number_employee
            };
        case types.userList:
            return {
                ...state,
                list: action.payload
            };
        case types.userDelete:
            return {
                ...state,
                activeEvent: null
            };
        case types.userSetActive:
            return {
                ...state,
                active: action.payload.user
            };
        case types.userUnsetActive:
            return {
                ...state,
                active: null
            };

        case types.userOpenModal:
            return {
                ...state,
                openForm: true
            };
        case types.userCloseModal:
            return {
                ...state,
                openForm: false,
                active: null
            };
        case types.userOpenModalModules:
            return {
                ...state,
                openFormModule: true
            };
        case types.userCloseModalModules:
            return {
                ...state,
                openFormModule: false
            };

        default:
            return state;
    }
};
