import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5
    },
    active: null,
    openForm: false,
    partnetshipList: [],
};

export const serviceSocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.socialServiceShow:
            return {
                ...state,
                pagination: action.payload.pagination
            };
        case types.partnertshipsShow:
            return {
                ...state,
                partnetshipList: action.payload.list
            };
        case types.setSocialServiceActive:
            return {
                ...state,
                active: action.payload
            };
        case types.startUnsetActiveServiceSocial:
            return {
                ...state,
                active: null
            };
        default:
            return state;
    }
};


