import { createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";

const initialState = {
    report: null,
};

export const InscriptionsByCycle = createSlice({
    name: "InscriptionsByCycle",
    initialState,
    reducers: {
        setReport: (state, action) => {
            state.report = action.payload;
        },
        clearReport: (state) => {
            state.report = initialState.report;
        },
    },
});

export const { setReport, clearReport } = InscriptionsByCycle.actions;

export default InscriptionsByCycle.reducer;

// Actions
export const startShow = ({ cycles }) => {
    return async (dispatch) => {

        dispatch(startUILoading());

        const cycles_data = `${cycles.year}-${cycles.sub_cycle}`;

        const params = new URLSearchParams();

        params.set("cycles", cycles_data);

        const resp = await Call(
            `operators/reports/academic-records/inscription-and-re-registration`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setReport(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startExportReport = ({ cycles }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const cycles_data = `${cycles.year}-${cycles.sub_cycle}`;

        const params = new URLSearchParams();

        params.set("export", 1);
        params.set("cycles", cycles_data);

        const response = await CallWithFormDataFile(
            `operators/reports/academic-records/inscription-and-re-registration`,
            "GET",
            params.toString()
        );
        downloadBlob(response, "Reporte de inscripciones y reinscripciones");

        dispatch(stopUILoading());
    };
};
