import { types } from "../../types";

const initialState = {
    list: [],
    pagination: {
        data: [],
        per_page: 5,
    },


    active: null,
    openForm: false,
};

export const departmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.departmentAll:
            return {
                ...state,
                list: action.payload,
            };
        case types.departmentShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.departmentSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.departmentUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.departmentOpenForm:
            return {
                ...state,
                openForm: true,
            };
        case types.departmentCloseForm:
            return {
                ...state,
                openForm: false,
                active: null,
            };
        default:
            return state;
    }
};
