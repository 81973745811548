import { types } from "../../types";

const initialState = {
    openModal: false,
    mode: "create",
    info: null,
    treeData: [],
    edit_mode:false,
 };

export const organizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.organizationOpenModal:
            return {
                ...state,
                ...action.payload,
                openModal: true,
            };
        case types.organizationCloseModal:
            return {
                ...state,
                openModal: false,
            };
        case types.organizationSetTreeData:
            return {
                ...state,
                treeData: action.payload,
            };
        case types.organizationEditModeToggle:
            return {
                ...state,
                edit_mode: !state.edit_mode,
            };

        default:
            return state;
    }
};
