import { createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    setCalendars
} from "../../actions/operators/AcademicSchedules";

const initialState = {
    modal: false,
    active: null,
    modality: null,
    response: null,
};

export const subjectsByStudentApp = createSlice({
    name: "subjectsByStudentApp",
    initialState,
    reducers: {
        toggleModal: (state) => {
            state.modal = !state.modal;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        setResponse: (state, action) => {
            state.response = action.payload;
        },
        setSchedules: (state, action) => {
            state.response = {
                ...state.response  || {},
                schedules: action.payload,
            };
        },
        setModality: (state, action) => {
            state.modality = action.payload;
        },
    },
});

export const { toggleModal, setActive, setResponse,setSchedules, setModality } =
    subjectsByStudentApp.actions;

export default subjectsByStudentApp.reducer;

// ACTIONS

export const startGetSubjectsByStudent = ({
    academic_records_id,
    cycle_id,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        if (academic_records_id)
            params.set("academic_records_id", academic_records_id);
        if (cycle_id) params.set("cycle_id", cycle_id);
        const resp = await Call(
            "students/getClassByStudent",
            "GET",
            params.toString()
        );
        if (resp.success) {
            dispatch(setResponse(resp.success));
            dispatch(setCalendars(resp.success.calendar, `STUDENT_SUBJECTS`));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startGetMySubjects = ({ academic_records_id, cycle_id }) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        if (academic_records_id)
            params.set("academic_records_id", academic_records_id);
        if (cycle_id) params.set("cycle_id", cycle_id);

        const resp = await Call(
            "students/getClassByStudent/student",
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setResponse(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};
