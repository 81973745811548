import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    listProviders: [],
    openForm: false,
};

export const providersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.showProviders:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.showListProviders:
            return {
                ...state,
                listProviders: action.payload.list,
            };
        case types.providersOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.providersCloseModal:
            return {
                ...state,
                openForm: false,
            };
        default:
            return state;
    }
};
