import { types } from "../../types";

const initialState = {
    hash: "",
    service: "",
    cl_folio: "",
    status: "",
    cl_referencia: "",
    cl_concepto: "",
};

export const banbajioReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.startSaveHashBB:
            return {
                ...state,
                ...action.payload,
            };
        case types.startStatusPaymentBB:
            return {
                ...state,
                status: action.payload.status,
            };
        case types.startClearDataBB:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
};
