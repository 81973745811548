import React from "react";
import { Modules_TeachersSession } from "./Modules_TeachersSession";

export const TeacherModules = () => {
    return (
        <>
            <Modules_TeachersSession />
        </>
    );
};
