import { types } from "../../../types";

const initialState = {
    openCalendar: false,
    calendar: [],
    total_hours: 0,
};

export const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.calendarShow:
            return {
                ...state,
                calendar: action.payload.calendars,
                total_hours: action.payload.total_hours,
            };
        case types.calendarOpenModal:
            return {
                ...state,
                openCalendar: true,
            };
        case types.calendarCloseModal:
            return {
                ...state,
                openCalendar: false,
                calendar: [],
            };

        default:
            return state;
    }
};
