import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    paginationGroups: {
        data: [],
        per_page: 5,
    },
    list: [],
    active: null,
    // active: JSON.parse(localStorage.getItem("curriculum")) || null,
    openForm: false,
    openFormUsers: false,
    openFormGroups: false,
    openTabulator: false,
    openReportModal: false,
    tree: [],
    tabulators: [],
};

export const curriculaReducer = (state = initialState, action) => {
    switch (action.type) {
        //v2
        case types.curriculaShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };

        case types.curriculaShowGroups:
            return {
                ...state,
                pagination: action.payload.paginationGroups,
            };

        case types.curriculaList:
            return {
                ...state,
                list: action.payload.list,
            };

        case types.curriculaSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.curriculaUnsetActive:
            return {
                ...state,
                active: null,
            };

        case types.curriculaOpenForm:
            return {
                ...state,
                openForm: true,
            };
        case types.curriculaCloseForm:
            return {
                ...state,
                openForm: false,
            };
        case types.curriculaOpenModalUsers:
            return {
                ...state,
                openFormUsers: true,
            };
        case types.curriculaCloseModalUsers:
            return {
                ...state,
                openFormUsers: false,
            };
        case types.curriculaOpenModalGroups:
            return {
                ...state,
                openFormGroups: true,
            };
        case types.curriculaCloseModalGroups:
            return {
                ...state,
                openFormGroups: false,
            };
        case types.curriculaOpenReportModal:
            return {
                ...state,
                openReportModal: true,
            };
        case types.curriculaCloseReportModal:
            return {
                ...state,
                openReportModal: false,
            };
        case types.curriculaSetTree:
            return {
                ...state,
                tree: action.payload,
            };
        case types.curriculaClean:
            return {
                ...state,
                tree: [],
            };

        //v1

        // case types.degreeOpenTabulator:
        //     return {
        //         ...state,
        //         openTabulator: true,
        //     };
        // case types.degreeCloseTabulator:
        //     return {
        //         ...state,
        //         openTabulator: false,
        //     };
        // case types.degreesShowTabulator:
        //     return {
        //         ...state,
        //         tabulators: action.payload.tabulator,
        //     };

        default:
            return state;
    }
};
