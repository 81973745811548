import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    Call,
    CallWithFormData,
    mergeFormData,
    objectToFormData,
} from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";

const initialState = {
    pagination: {
        data: [],
        per_page: 3,
    },
    list: [],
};

export const News = createSlice({
    name: "News",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
        clearState: () => {
            return initialState;
        },
    },
});
export const { setPagination, setList, clearPagination, clearState } =
    News.actions;

export default News.reducer;

// Actions
export const startShowNews = ({
    page = 1,
    query = "",
    number_rows = 3,
    asList = 0,
}) => {
    return async (dispatch) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const params = new URLSearchParams();

        if (asList) {
            params.set("asList", asList);
        } else {
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
        }

        const resp = await Call(`operators/news/show`, "GET", params);

        if (resp.success) {
            if (asList) {
                dispatch(setList(resp.success));
            } else {
                dispatch(setPagination(resp.success));
            }
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startSaveNews = (
    values,
    paginationValues,
    setErrors,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let data = objectToFormData(values);
        data.delete("files");
        data.delete("removed_files");
        data.delete("is_visible");

        const formData = new FormData();

        if (values?.files) {
            values.files.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });
        }

        const resp = await CallWithFormData(
            `operators/news/create`,
            "POST",
            mergeFormData(data, formData)
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Aviso creado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(startShowNews(paginationValues));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startEditNews = (
    news_id,
    values,
    paginationValues,
    setErrors,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let data = objectToFormData(values);
        data.delete("files");
        data.delete("is_visible");

        const formData = new FormData();

        if (values?.files) {
            values.files.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });
        }

        const resp = await CallWithFormData(
            `operators/news/edit/${news_id}`,
            "POST",
            mergeFormData(data, formData)
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Aviso editado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(startShowNews(paginationValues));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteNews = (news_id, paginationValues, setErrors) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());

            const resp = await Call(
                `operators/news/delete/${news_id}`,
                "DELETE"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Recurso eliminado exitosamente.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
                dispatch(startShowNews(paginationValues));
            } else {
                errorHandler(resp, setErrors);
            }

            dispatch(stopUILoading());
        });
    };
};

export const startShowNewsByUsersType = (
    { page = 1, query = "", number_rows = 3, asList = 0 },
    users_type
) => {
    return async (dispatch) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const params = new URLSearchParams();

        if (asList) {
            params.set("asList", asList);
        } else {
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
            params.set("users_type", users_type);
        }

        let url;
        switch (users_type) {
            case "operator":
                url = "operators/news/filter";
                break;
            case "teacher":
                url = "teachers/news/filter";
                break;
            case "student":
                url = "students/news/filter";
                break;
        }

        const resp = await Call(url, "GET", params);

        if (resp.success) {
            if (asList) {
                dispatch(setList(resp.success));
            } else {
                dispatch(setPagination(resp.success));
            }
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startSendEmail = (news_id, distribution_list_id) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/news/sendEmail/${news_id}/${distribution_list_id}`,
            "POST"
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Aviso enviado por correo.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};
