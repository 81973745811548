import React from "react";
import { Modules_TI } from "../administrator/Modules_TI";
import { Modules_Administratives } from "./Modules_Administratives";
import { Modules_RH } from "./Modules_RH";
import { Modules_Shopping } from "./Modules_Shopping";
import { Modules_School_Control } from "./Modules_School_Control";
import { Modules_Cashing } from "./Modules_Cashing";
import { Modules_Academic } from "./Modules_Academic";
import { Modules_Reports } from "./Modules_Reports";

export const UsersModules = () => {
    return (
        <>
            <Modules_Reports />
            <Modules_TI />
            <Modules_Administratives />
            <Modules_RH />
            <Modules_Academic />
            <Modules_School_Control />
            <Modules_Shopping />
            <Modules_Cashing />
        </>
    );
};
