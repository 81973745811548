import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 25,
    },
    validated: {
        file_name: "",
        status: 0,
        description: "",
        control_folio: "",
        num_lote: 0,
        as: "",
    },
};

export const electronicTitlesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.showElectronicTitle:
            return {
                ...state,
                pagination: action.payload,
            };
        case types.validateElectronicTitle:
            return {
                ...state,
                validated: action.payload,
            };

        default:
            return state;
    }
};
