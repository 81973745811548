import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import { Call } from "../../../helpers/fetch";
import { setSchedules } from "../../slices/components/SubjectsByStudentApp";
import { types } from "../../types";
import { startUILoading, stopUILoading } from "../administrador/ui/ui";
import { startGetScheduleByClassroom } from "./Classroom";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";
import { weekDaysENArray } from "../../../components/Options/OptionsWeekDays";

export const forceSwal = (
    errorsArray = "",
    canRegister = false,
    onSuccess = () => {}
) => {
    const tableHtml = `
        <div className="table-schedule-errors-container">
            <p>Se detectaron los siguientes choques de horarios:</p>
            <table class="table-schedule-errors" border="1">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Día</th>
                        <th>Horario</th>
                        <th>Fechas</th>
                        <th>Materia</th>
                        <th>Edificio>>Aula</th>
                        <th>Docente</th>
                        <th>Grupo</th>
                        <th>Ver conflicto</th>
                    </tr>
                </thead>
                <tbody>
                    ${errorsArray
                        .map(
                            (error) => `
                        <tr>
                            <td>${error.type}</td>
                            <td>${error.day}</td>
                            <td>${error.times}</td>
                            <td>${error.dates}</td>
                            <td>${error.className}</td>
                            <td>${error.building + ">>" + error.classroom}</td>
                            <td>${error.teacher || "--"}</td>

                            ${
                                error?.category === "normal"
                                    ? `
                                <td>
                                    ${
                                        error.academic_group_id &&
                                        error.academic_group_code
                                            ? `<a
                                                style="color: inherit; text-decoration: underline;"
                                                href="/app/operators/academicgroups/${error.academic_group_id}"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                ${error.academic_group_code}
                                            </a>`
                                            : "--"
                                    }
                                </td>
                                <td>
                                    ${
                                        error.schedule_conflict
                                            ? `<a
                                            href="/app/operators/academicgroups/${error.academic_group_id}?event_id=${error.schedule_conflict}"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i
                                                class="fas fa-eye"
                                                aria-hidden="true"
                                                style="color: rgb(0, 85, 147); font-size: 18px"
                                            ></i>
                                        </a>`
                                            : "--"
                                    }
                                </td>
                            `
                                    : `
                                <td></td>
                                <td></td>
                                `
                            }
                        </tr>
                    `
                        )
                        .join("")}
                </tbody>
            </table>
        </div>
    `;

    if (!canRegister) {
        Swal.fire({
            title: "Conflicto en horario",
            html: `
                <p style="text-align: center">
                    ${tableHtml}
                </p>
                <br/>
                <p>No se permiten los empalmes de horarios en espacios de tipo <strong>aula</strong>.</p>
            `,
            icon: "warning",
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonColor: "gray",
            cancelButtonText: "Cerrar",
            width: 1100,
            // didClose: () => {
            //     dispatch(clearCalendarDisponibility());
            // }
        });
        return;
    }

    // Considerar que los botones de confirmar y eliminar están al revés en ambos Swals
    Swal.fire({
        title: "Alerta",
        html: `
            <p style="text-align: center">
                ${tableHtml}
            </p>
            <br/>
            <p>Sin embargo, es posible realizar la operación debido a que no se trata de un espacio de tipo <strong>aula</strong>.</p>
            <p style="text-align: center">
                ¿Desea continuar y realizar un <strong>empalme</strong> de horarios?
            </p>
            `,
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "No, cancelar",
        confirmButtonColor: "#134586",
        denyButtonText: "Sí, empalmar",
        denyButtonColor: "#DE3C4B",
        customClass: {
            confirmButton: "confirm-button-class",
            denyButton: "deny-button-class",
        },
        showCloseButton: true,
        didOpen: () => {
            const confirmButton = document.querySelector(
                ".confirm-button-class"
            );
            const denyButton = document.querySelector(".deny-button-class");

            if (confirmButton && denyButton) {
                confirmButton.style.marginLeft = "20px";
                denyButton.style.marginLeft = "20px";
            }
        },
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        reverseButtons: true,
        width: 900,
    }).then((result) => {
        if (result.isDenied) {
            onSuccess();
        }
    });
};

export const startCheckDisponibility = (
    values,
    academic_schedule_id = null,
    callback = () => {},
    force = false,
    isExtracurricular = false
) => {
    return async (dispatch) => {
        const canValidate =
            (isExtracurricular || values.school_subjects_id) &&
            (typeof values.teacher_id === "number" ||
                values.teacher_id?.length > 0) &&
            values.day &&
            // values.from &&
            // values.to &&
            values.multiple_dates.length > 0 &&
            (!values.has_monday ||
                (values.has_monday &&
                    values.monday_classroom_id &&
                    values.monday_start &&
                    values.monday_end)) &&
            (!values.has_tuesday ||
                (values.has_tuesday &&
                    values.tuesday_classroom_id &&
                    values.tuesday_start &&
                    values.tuesday_end)) &&
            (!values.has_wednesday ||
                (values.has_wednesday &&
                    values.wednesday_classroom_id &&
                    values.wednesday_start &&
                    values.wednesday_end)) &&
            (!values.has_thursday ||
                (values.has_thursday &&
                    values.thursday_classroom_id &&
                    values.thursday_start &&
                    values.thursday_end)) &&
            (!values.has_friday ||
                (values.has_friday &&
                    values.friday_classroom_id &&
                    values.friday_start &&
                    values.friday_end)) &&
            (!values.has_saturday ||
                (values.has_saturday &&
                    values.saturday_classroom_id &&
                    values.saturday_start &&
                    values.saturday_end));

        if (canValidate || force) {
            dispatch(startUILoading());

            //* Arreglo con los días checkeados para validar
            let schedules_to_check = [];

            if (force) {
                schedules_to_check = [{ ...values, isExtracurricular }];
            } else {
                schedules_to_check = weekDaysENArray
                    .map((day) => ({
                        hasDay: values[`has_${day}`],
                        academic_group_id: values.academic_group_id,
                        cycle_id: values.cycle_id,
                        classroom_id: values[`${day}_classroom_id`],
                        teacher_id: values.teacher_id,
                        day: day.charAt(0).toUpperCase() + day.slice(1),
                        start: values[`${day}_start`],
                        end: values[`${day}_end`],
                        from: values.from,
                        to: values.to,
                        multiple_dates: values.multiple_dates,
                        isExtracurricular,
                    }))
                    .filter((day) => day.hasDay);
            }

            const resp = await Call(
                `operators/schedules/check-disponibility`,
                "POST",
                { schedules_to_check, academic_schedule_id }
            );

            let isDisponible = false;
            let errorsArray = [];

            if (resp.success) {
                isDisponible = true;
                dispatch(
                    setCalendarDisponibility({
                        isDisponible,
                        errorsArray: [],
                        successText: resp.success.msg,
                    })
                );
            } else {
                if (resp.status_code === 422) {
                    isDisponible = false;
                    errorsArray.push({
                        conflict: false,
                        can_register: false,
                        type: "CAMPOS FALTANTES",
                        info: resp?.message,
                        msg: resp?.message,
                    });
                } else {
                    errorsArray = resp?.errors?.data || [];
                    isDisponible = resp?.errors?.can_register;
                }

                dispatch(
                    setCalendarDisponibility({
                        isDisponible,
                        errorsArray,
                        successText: null,
                        canRegister: isDisponible,
                    })
                );
            }

            callback(isDisponible, errorsArray);

            dispatch(stopUILoading());
        } else {
            // dispatch(clearCalendarDisponibility());
            callback(false, []);
        }
    };
};

export const startCreate = (values, setErrors = {}, callback = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call("operators/schedules/create", "PUT", values);

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
                toast: true,
                timer: 3000,
                position: "bottom-end",
            });
            callback(resp.success);
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startUpdate = (
    values,
    id,
    setErrors = {},
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`operators/schedules/${id}`, "POST", values);

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Cambios guardados.",
                toast: true,
                timer: 3000,
                position: "bottom-end",
                icon: "success",
            });

            callback(resp.success);
        } else {
            if (resp.error.msg) {
                errorHandler(resp, setErrors);
            }
        }

        dispatch(stopUILoading());
    };
};

export const startDelete = (schedule, setErrors = {}, callback = () => {}) => {
    return async (dispatch) => {
        await deleteConfirmModal(
            async () => {
                dispatch(startUILoading());
                const resp = await Call(
                    `operators/schedules/${schedule.id}`,
                    "DELETE"
                );

                if (resp.success) {
                    callback(schedule);
                    // callback(resp.success);

                    Swal.fire({
                        title: "Exito",
                        text: "Se ha eliminado el horario correctamente",
                        icon: "success",
                        toast: true,
                        position: "bottom-end",
                        timer: 8000,
                    });
                } else {
                    errorHandler(resp, setErrors);
                }
                dispatch(stopUILoading());
            },
            false,
            false
        );
    };
};

export const updateScheduleInLocal = (
    scheduleUpdated,
    action = "update",
    name,
    isOwnerTable = false
) => {
    return async (dispatch, getState) => {
        // //*Calendario antiguo
        const { calendars, schedules } = getState().calendarSchedule;
        const { response } = getState().subjectsByStudentApp;

        const calendar = calendars.find((c) => c.name === name);

        if (!calendar) {
            console.warn("Calendario no encontrado:", name);
            return;
        }

        let week_schedule_update = [];
        // //*actualizar el calendario mapeando el anterior
        // if (calendar.length > 0) {
        week_schedule_update = calendar.week_schedule.map((schedule) => {
            const data = { ...schedule };

            switch (action) {
                case "insert":
                    //*Comparar el calendario actualizado (traido de la respuesta de insertar)
                    //*con el calendario antiguo(traido del estado)
                    if (scheduleUpdated.day === data.day) {
                        //*actualizar el calendario del state con los nuevos datos
                        data.schedule = [...data.schedule, scheduleUpdated];
                    }
                    break;

                case "update":
                    //*Filtrar por id donde sea diferente el calendario del state
                    //*asi se obtiene el nuevo id para distinguir cual es el nuevo
                    let schedules_temp = data.schedule.filter(
                        (sc) => sc.id !== scheduleUpdated.id
                    );

                    //*Comparar el calendario actualizado (traido de la respuesta de insertar)
                    //*con el calendario antiguo(traido del estado)
                    //*cuando ambos tengan el mismo dia
                    if (scheduleUpdated.day === data.day) {
                        //*actualizar el calendario temporal(donde los id sean diferentes) con los nuevos datos
                        data.schedule = [...schedules_temp, scheduleUpdated];
                    } else {
                        //*actualizar el calendario antiguo con el calendario temporal
                        data.schedule = schedules_temp;
                    }

                    break;
                case "delete":
                    let schedules_temp_delete = data.schedule.filter(
                        (sc) =>
                            sc.school_subjects_id !==
                            scheduleUpdated.school_subjects_id
                    );
                    data.schedule = schedules_temp_delete;

                    break;
            }

            return data;
        });
        // }

        let updateCalendar = {
            ...calendar,
            week_schedule: week_schedule_update,
        };

        //*Actualizar la lista

        let updateScheduleList = isOwnerTable
            ? [...response.schedules]
            : [...schedules];

        switch (action) {
            case "insert":
                updateScheduleList.push(scheduleUpdated);

                break;

            case "update":
                //*Obtener el index del elemento que se va a sustituir
                const indexOfScheduleToUpdate = updateScheduleList.findIndex(
                    (schedule) => schedule.id === scheduleUpdated.id
                );

                //*Determinar si el elemento con el mismo id ya existe en la lista updateScheduleList
                if (indexOfScheduleToUpdate !== -1) {
                    //* Si se encontró el elemento, reemplázalo en la lista
                    updateScheduleList[indexOfScheduleToUpdate] =
                        scheduleUpdated;
                } else {
                    //* Si no se encontró el elemento, agrégalo a la lista
                    updateScheduleList.push(scheduleUpdated);
                }

                break;
            case "delete":
                updateScheduleList = updateScheduleList.filter(
                    (schedule) =>
                        schedule.school_subjects_id !=
                        scheduleUpdated.school_subjects_id
                );

                break;
        }

        dispatch(setCalendars(updateCalendar, name));

        if (isOwnerTable) {
            dispatch(setSchedules(updateScheduleList));
        } else {
            dispatch(setSchedulesList(updateScheduleList));
        }
    };
};

export const startGetCompactedSchedulesByCycle = (cycle_id) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        params.set("all", true);
        const resp = await Call(
            `operators/compacted/schedules/${cycle_id}`,
            "GET",
            params.toString()
        );
        dispatch(startSetCompactedSchedulesByCycle(resp?.success?.classes));

        dispatch(stopUILoading());
    };
};

export const getExtracurricularSchedules = ({ category, cycle_id }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("category", category);

        if (cycle_id) {
            params.set("cycle", cycle_id);
        }

        const resp = await Call(
            `operators/schedules/show/extracurricular`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(
                setCalendars(resp.success.calendar, `EXTRACURRICULAR_SCHEDULE`)
            );
            dispatch(setSchedulesList(resp.success.schedules));
            dispatch(
                setSchedulesAcademicGroupId(resp.success.academic_group_id)
            );
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startAddGroupToCompactedSchedule = (
    academic_group_id,
    academic_schedule_id,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(
            `operators/compacted/schedules/add/group/${academic_group_id}/${academic_schedule_id}`,
            "PUT"
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Inscripción exitosa.",
                toast: true,
                timer: 3000,
                position: "bottom-end",
                icon: "success",
            });
            callback();
            const schedule = resp.success;
            dispatch(startSetActiveSchedule(schedule));
            // if (schedule.classroom_id && schedule.cycle_id) {
            //     dispatch(
            //         startGetScheduleByClassroom(
            //             schedule.classroom.id,
            //             schedule.cycle.id
            //         )
            //     );
            // }
        }
        dispatch(stopUILoading());
    };
};

export const startRemoveGroupFromCompactedSchedule = (
    academic_group_id,
    academic_schedule_id,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(
            `operators/compacted/schedules/remove/group/${academic_group_id}/${academic_schedule_id}`,
            "DELETE"
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Eliminación exitosa.",
                toast: true,
                timer: 1000,
                position: "bottom-end",
                icon: "success",
            });
            callback();
            const schedule = resp.success;
            dispatch(startSetActiveSchedule(schedule));
            // if (schedule.classroom_id && schedule.cycle_id) {
            //     dispatch(
            //         startGetScheduleByClassroom(
            //             schedule.classroom.id,
            //             schedule.cycle.id
            //         )
            //     );
            // }
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

//*Obtener los estudiantes inscritos en el horario seleccionado
export const getEnrollments = (academic_schedule_id) => {
    return async (dispatch) => {
        const params = new URLSearchParams();
        dispatch(startUILoading());
        const resp = await Call(
            `operators/schedules/getEnrollments/${academic_schedule_id}`,
            "GET",
            params.toString()
        );
        dispatch(setEnrollments(resp.success));
        dispatch(stopUILoading());
    };
};

export const startSelectectExtracurricularSchoolSubject = (
    enrollment_id,
    values,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/schedules/select/school-subject/${enrollment_id}`,
            "POST",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Materia registrada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            callback();
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startSwapAcademicScheduleTeacher = (
    values,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const { schedule_id, teacher_id, date_from } = values;

        const resp = await Call(
            `operators/schedules/swap-teacher/${schedule_id}`,
            "PATCH",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Docente del horario cambiado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });

            dispatch(startSetActiveSchedule(resp.success));
            callback();
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

// *ACTIONS
export const setCalendar = (data) => ({
    type: types.calendarScheduleSetCalendar,
    payload: data,
});

export const setCalendars = (schedule, name) => {
    return async (dispatch, getState) => {
        const { calendars } = getState().calendarSchedule;

        const calendars_schedule = [
            ...calendars.filter((c) => c.name !== name),
            { ...schedule, name },
        ];

        return dispatch({
            type: types.calendarScheduleSetCalendars,
            payload: calendars_schedule,
        });
    };
};

export const setSchedulesAcademicGroupId = (data) => ({
    type: types.calendarScheduleSetSchedulesAcademicGroupId,
    payload: data,
});

export const setCleanCalendar = () => ({
    type: types.calendarScheduleSetCleanCalendar,
});

export const setSchedulesList = (data) => ({
    type: types.calendarScheduleSetSchedulesList,
    payload: data,
});

export const startOpenModal = (name) => ({
    type: types.calendarScheduleOpenModal,
    payload: name,
});

export const startCloseModal = () => ({
    type: types.calendarScheduleCloseModal,
});

export const startEnableForm = () => ({
    type: types.calendarScheduleEnableForm,
});
export const startDisableForm = () => ({
    type: types.calendarScheduleDisableForm,
});

export const startOpenModalScheduleSubjects = () => ({
    type: types.calendarScheduleOpenModalScholarSubjectsByTeacher,
});

export const startCloseModalScheduleSubjects = () => ({
    type: types.calendarScheduleCloseModalScholarSubjectsByTeacher,
});

export const startSetActiveSchedule = (data) => ({
    type: types.calendarScheduleSetActive,
    payload: data,
});

export const startUnsetActiveSchedule = () => ({
    type: types.calendarScheduleUnsetActive,
});

export const setSelectedSlot = (data) => ({
    type: types.calendarScheduleSetSlot,
    payload: data,
});

export const setBuilding = (data) => ({
    type: types.calendarScheduleSetBuilding,
    payload: data,
});

export const clearBuilding = () => ({
    type: types.calendarScheduleClearBuilding,
});

export const setClassroom = (data) => ({
    type: types.calendarScheduleSetClassroom,
    payload: data,
});

export const clearClassroom = () => ({
    type: types.calendarScheduleClearClassroom,
});

export const startSetCompactedSchedulesByCycle = (data) => ({
    type: types.calendarSetCompactedSchedulesByCycle,
    payload: data,
});

export const setEnrollments = (data) => ({
    type: types.calendarSetEnrollments,
    payload: data,
});

export const SetEmptyEnrollments = () => ({
    type: types.calendarSetEmptyEnrollments,
});

export const setCalendarDisponibility = (data) => ({
    type: types.calendarSetCalendarDisponibility,
    payload: data,
});

export const clearCalendarDisponibility = () => ({
    type: types.calendarClearCalendarDisponibility,
});

export const setScheduleInStudentProfile = (data) => ({
    type: types.calendarScheduleInStudentProfile,
    payload: data,
});
