import { types } from "../../types";
const initialState = {
    pagination: {
        data: [],
        per_page: 10,
    },
    openFormGroup: false,
    active: null,
    studentsByGroup: [],
    groupsByCurriculum: [],
    calendar: {
        min: 7,
        max: 21,
        week_schedule: [],
    },
    cycleSelected: null,
    blockGroup: false,
    academicGroupsCompactedList: {
        data: [],
        per_page: 10,
    },
};

export const academicGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.showAcademicGroups:
            return {
                ...state,
                pagination: action.payload,
            };

        case types.academicGroupsOpenModal:
            return {
                ...state,
                openFormGroup: true,
            };

        case types.academicGroupsCloseModal:
            return {
                ...state,
                openFormGroup: false,
            };

        case types.academicGroupsSetActive:
            return {
                ...state,
                active: action.payload,
            };

        case types.academicGroupsUnsetActive:
            return {
                ...state,
                active: null,
            };

        case types.studentsByGroupSetActive:
            return {
                ...state,
                studentsByGroup: action.payload,
            };

        case types.studentsByGroupUnSetActive:
            return {
                ...state,
                studentsByGroup: null,
            };

        case types.academicGroupsGetByCurricula:
            return {
                ...state,
                groupsByCurriculum: action.payload,
            };

        case types.academicGroupsSetCalendar:
            return {
                ...state,
                calendar: action.payload,
            };

        case types.academicGroupsSetCycleSelected:
            return {
                ...state,
                cycleSelected: action.payload,
            };

        case types.academicGroupBlockGroup:
            return {
                ...state,
                blockGroup: action.payload,
            };
        case types.academicGroupCompactedList:
            return {
                ...state,
                academicGroupsCompactedList: action.payload,
            };
        case types.academicGroupCompactedListClear:
            return {
                ...state,
                academicGroupsCompactedList:
                    initialState.academicGroupsCompactedList,
            };

        default:
            return state;
    }
};
