import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormData,
    objectToFormData,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
    },
    modalOpen: false,
    active: null,
    clarificationMessages: [],
};

export const studentClarification = createSlice({
    name: "studentClarification",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        unsetActive: (state) => {
            state.active = null;
        },
        setOpenModal: (state) => {
            state.modalOpen = true;
        },
        setCloseModal: (state) => {
            state.modalOpen = false;
        },
        reloadChat: (state, action) => {
            state.clarificationMessages = action.payload;
        },
    },
});

export const {
    setPagination,
    setActive,
    setOpenModal,
    setCloseModal,
    unsetActive,
    reloadChat,
} = studentClarification.actions;

export default studentClarification.reducer;

export const startShowClarificationOperator = ({
    page,
    query = "",
    status = "",
    category = "",
    number_rows,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("status", status);
        params.set("category", category);
        params.set("per_page", number_rows);

        const resp = await Call(
            "students/clarification",
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setPagination(resp.success.clarifications));
        }

        dispatch(stopUILoading());
    };
};

export const startShowClarificationStudent = ({
    page,
    query = "",
    status = "",
    category = "",
    number_rows,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("status", status);
        params.set("category", category);
        params.set("per_page", number_rows);

        const resp = await Call(
            "students/clarification/show",
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setPagination(resp.success.clarifications));
        }

        dispatch(stopUILoading());
    };
};

export const startCreateClarificationStudent = (
    { category, message },
    setErrors = null,
    callback
) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());


        const form = {
            category,
            message,
        };

        const resp = await CallWithFormData(
            `students/clarification`,
            "POST",
            objectToFormData(form)
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
                toast:true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(setActive(resp.success));

            callback();
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startTrackingClarificationStudent = (
    data = {},
    history,
    setErrors = null
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("folio", data.folio);
        params.set("student_number", data.student_number);

        const resp = await Call(
            `students/clarification/load`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(startSetActiveClarification(resp.success));
            navigate(`/solicitud/aclaracion/estudiantes/detalle`);
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startEditClarification = (
    id = null,
    data = {},
    setErrors = null,
    callback
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let formData = new FormData();

        if (data.note_advisor) {
            formData.append("note_advisor", data.note_advisor);
        }
        if (data.status) {
            formData.append("status", data.status);
        }
        if (data.department) {
            formData.append("department", data.department);
        }
        if (data.category) {
            formData.append("category", data.category);
        }
        if (data.file) {
            formData.append("file", data.file);
        }

        const resp = await CallWithFormData(
            `students/clarification/edit/${id}`,
            "POST",
            formData
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Actualizacíon exitosa.",
                icon: "success",
                toast:true,
                position: "bottom-end",
                timer: 8000,
            });
            callback();
            dispatch(setActive(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

//*Chat
export const startReloadClarificationChat = (clarification) => {
    return async (dispatch, getState) => {


        if (clarification) {
            let {session} = getState().auth;

            const params = new URLSearchParams();

            params.set("as", session);
            const resp = await Call(
                `students/clarification/message/find/${clarification.id}`, 'GET', params.toString()
            );
            if (resp.success) {

                dispatch(reloadChat(resp.success.messages));
            }
        }
    };
};

export const startSendMessageClarification = (
    { clarification, message, file },
    setErrors,
    callback
) => {
    return async (dispatch, getState) => {
        dispatch(startUILoading());

        let user_id = getState().auth.user.id;
        let as = getState().auth.session;

        const form = {
            message,
            as,
            file,
            user_id,
        };

        const resp = await CallWithFormData(
            `students/clarification/send/message/${clarification.id}`,
            "POST",
            objectToFormData(form)
        );

        if (resp.success) {
            callback();
            dispatch(setActive(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};
