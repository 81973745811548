import { types } from "../../../types";

const initialState = {
    openTabulators: false,
    tabulators: []
};

export const tabulatorReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.teacherShowTabulators:
            return {
                ...state,
                tabulators: action.payload
            };
        case types.teacherOpenTabulators:
            return {
                ...state,
                openTabulators: true
            };
        case types.teacherCloseTabulators:
            return {
                ...state,
                openTabulators: false,
                tabulators: [],
            };

        default:
            return state;
    }
};

