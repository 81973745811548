import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";

const initialState = {
    report: {},
};

export const EndOfMonth = createSlice({
    name: "EndOfMonth",
    initialState,
    reducers: {
        setReport: (state, action) => {
            state.report = action.payload;
        },
        clearReport: (state) => {
            state.report = initialState.report;
        },
    },
});
export const { setReport, clearReport } = EndOfMonth.actions;

export default EndOfMonth.reducer;

// Actions
export const startGetReport = ({
    payable_type,
    start_date,
    end_date,
    exportFlag = 0,
    country = "Todos",
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let resp;

        if (exportFlag) {
            // Descargar Excel
            let formData = new FormData();
            formData.append("payable_type", payable_type);
            formData.append("start_date", start_date);
            formData.append("end_date", end_date);
            formData.append("export", exportFlag);

            if (country != "Todos") {
                formData.append("country", country);
            }

            resp = await CallWithFormDataFile(
                "services/payments/end-of-month-report",
                "POST",
                formData
            );
            downloadBlob(resp, "Reporte de cierre de mes");
        } else {

            const params = new URLSearchParams();

            params.set("payable_type", payable_type);
            params.set("start_date", start_date);
            params.set("end_date", end_date);
            params.set("export", exportFlag);


            if (country != "Todos") {
                params.append("country", country);
            }

            resp = await Call(
                `services/payments/end-of-month-report`,
                "GET",
                params
            );
            if (resp.success) {
                dispatch(setReport(resp.success));
            } else {
                errorHandler(resp);
            }
        }

        dispatch(stopUILoading());
    };
};

export const startSaveIncomes = (data, values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`operators/daily-income/register`, "PUT", data);

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Los registros se han actualizado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(startGetReport(values));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startClearReport = () => {
    return async (dispatch) => {
        dispatch(startUILoading());

        dispatch(clearReport());

        dispatch(stopUILoading());
    };
};
