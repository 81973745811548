import { types } from "../../../types";

const initialState = {
    teachers: [],
    pagination: {
        data: [],
        per_page: 5,
    },
    list: [],
    openForm: false,
};

export const modulesTeacherReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.moduleTeacherOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.modulesTeacherAll:
            return {
                ...state,
                list: action.payload
            };
        case types.modulesTeacherShow:
            return {
                ...state,
                pagination: action.payload.pagination
            };
        case types.moduleTeacherSetActive:
            return {
                ...state,
                active: action.payload
            };
        case types.moduleTeacherList:    
            return {
                ...state,
                list: action.payload
            }
        case types.moduleTeacherOpenForm:
            return {
                ...state,
                openForm: true
            };
        case types.moduleTeacherCloseForm:
            return {
                ...state,
                openForm: false,
                active: false
            };

        default:
            return state;
    }
};
