import React from 'react';
import { FaHeart } from "react-icons/fa";
import { useSelector } from 'react-redux';

export const FooterApp = () => {

    const { tiny_name } = useSelector((state) => state.config);


    return (
        <footer className={"footer"}>
            <small className="center">
                © {new Date().getFullYear()} made with <FaHeart style={{ color: "red" }} /> by -{" "}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={tiny_name === "UCG" ? "https://ucg.edu.mx/aviso-privacidad" : "https://ucuauhtemoc.edu.mx/aviso-privacidad"}
                >
                  UC
                </a>

            </small>

             {/* <br />
            <div className="social-bagdes">
                <a
                    href="https://github.com/ManzanoGlez"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="GitHub followers"
                        src="https://img.shields.io/github/followers/ManzanoGlez?label=github&style=social"
                    />
                </a>
            </div> */}


        </footer>
    );
}
