import React, { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { IconApp } from "./IconApp";
import { useSelector } from "react-redux";

const pxPerLevel = {
    0: "0px",
    1: "8px",
    2: "16px",
    3: "24px",
    4: "32px",
    5: "40px",
    6: "48px",
};

const SidebarNavAccordion = ({
    itemLevel,
    title,
    iconLeftClassName,
    children,
    color,
}) => {
    const { minimized } = useSelector((state) => state.sidebar);
    const { uiShowModulesCount } = useSelector((state) => state.ui);
    const [isShow, setIsShow] = React.useState(false);
    const [isHover, setIsHover] = React.useState(false);

    const hasChildren = children.filter((child) => child)?.length > 0;

    if (!hasChildren) {
        return <></>;
    }

    const handleHover = () => {
        setIsHover(!isHover);
    };

    useEffect(() => {
        setIsShow(uiShowModulesCount.length <= 3);
    }, [uiShowModulesCount]);

    return (
        <div style={{ marginTop: "5px" }}>
            <Accordion
                className="c-sidebar-nav-link"
                style={{ display: "grid", gridTemplateColumns: "1fr 7fr 1fr" }}
                onClick={() => {
                    setIsShow(!isShow);
                }}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
            >
                <span
                    style={{
                        width: minimized ? "35px" : "10px",
                        marginLeft: pxPerLevel[itemLevel],
                    }}
                >
                    <IconApp
                        iconClassName={iconLeftClassName}
                        color={isHover ? "inherit" : color}
                    />
                </span>
                {!minimized ? (
                    <strong
                        style={{
                            justifySelf: "start",
                            marginLeft: "10px",
                            color: isHover ? "inherit" : color,
                        }}
                    >
                        {title}
                    </strong>
                ) : (
                    isHover && (
                        <strong
                            style={{
                                justifySelf: "start",
                                marginLeft: "10px",
                                color: isHover ? "inherit" : color,
                            }}
                        >
                            {title}
                        </strong>
                    )
                )}
                {isShow ? (
                    <span style={{ justifySelf: "end" }}>
                        <IconApp
                            iconClassName="fa-solid fa-angle-down c-sidebar-nav-icon"
                            color={isHover ? "inherit" : color}
                        />
                    </span>
                ) : (
                    <span
                        style={{
                            justifySelf: "end",
                            marginLeft: !minimized ? "20px" : "0px",
                            color: isHover ? "inherit" : color,
                        }}
                    >
                        <IconApp
                            iconClassName="fa-solid fa-angle-right c-sidebar-nav-icon"
                            color={isHover ? "inherit" : color}
                        />
                    </span>
                )}
            </Accordion>
            <div style={{ marginLeft: pxPerLevel[itemLevel] }}>
                {isShow && children}
            </div>
        </div>
    );
};

export default SidebarNavAccordion;
