import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { errorHandler } from "../../../helpers/errorHandler";
import { Call, CallWithFormData } from "../../../helpers/fetch";
import { shoppingCartApp } from "./ShoppingCartApp";

const getJsonReferences = () => {
    try {
        const jsonObj = JSON.parse(localStorage.getItem("report-cache")) || null;
        return jsonObj;
    } catch (error) {
        localStorage.removeItem("report-cache");
        return null;
    }
};

const initialState = {
    reportData: getJsonReferences(),
};

export const genericReports = createSlice({
    name: "genericReports",
    initialState,
    reducers: {
        setReportData: (state, action) => {
            state.reportData = action.payload;
        },
    },
});

export const { setReportData } = genericReports.actions;
export default genericReports.reducer;

// Thunks

export const startOpenPDFReport = (values, id) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        window.open(
            `${values.url}/${id}`,
            values.title,
            "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no, width=1080, height=700"
        );
        dispatch(stopUILoading());
    };
};

export const startGetReportData = (url, id) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let resp = await Call(`${url}/${id}`, "POST");

        if (resp.success) {
            dispatch(setReportData(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startGetPostReportData = (url, id) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = {
            academic_record_id: id,
        };

        let resp = await Call(`${url}`, "POST", params);

        if (resp.success) {
            dispatch(setReportData(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startGetReferences = (student, shopingCart, goToReferences, toInvoice) => {
    return async (dispatch) => {
        const formData = new FormData();

        formData.append("student", JSON.stringify(student));
        formData.append("shopingCart", JSON.stringify(shopingCart));
        if (toInvoice) {
            formData.append("toInvoice", toInvoice);
        }




        const resp = await CallWithFormData(
            `services/payments/generate/references`,
            "POST",
            formData
        );

        if (resp.success) {
            localStorage.setItem("report-cache", JSON.stringify(resp.success));
            dispatch(setReportData(resp.success));
            goToReferences();
        }
    };
};
