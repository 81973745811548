import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    active: null,
    openForm: false,
};

export const practiceProfessionalReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.practiceProfessionalShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.PPSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.PPUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.PPOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.PPCloseModal:
            return {
                ...state,
                active: null,
                openForm: false,
            };
        case types.PartnershipsOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.PartnershipsCloseModal:
            return {
                ...state,
                active: null,
                openForm: false,
            };
        case types.PPSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.PPUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.PartnershipsSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.PartnershipsUnsetActive:
            return {
                ...state,
                active: null,
            };
        default:
            return state;
    }
};
