import { lazy } from "react";
//users
const LoginScreen = lazy(() =>
    import("../../views/administrador/auth/LoginScreen")
);
// const RegisterScreen = lazy(() => import("../../views/administrador/auth/RegisterScreen"));
const ForgotMyPassword = lazy(() =>
    import("../../views/administrador/auth/ForgotMyPassword")
);
const PasswordReset = lazy(() =>
    import("../../views/administrador/auth/PasswordReset")
);

//teachers
const LoginTeachersScreen = lazy(() =>
    import("../../views/administrador/auth/Teachers/LoginScreen")
);
const ForgotMyPasswordTeacher = lazy(() =>
    import("../../views/administrador/auth/Teachers/ForgotMyPassword")
);
const PasswordResetTeacher = lazy(() =>
    import("../../views/administrador/auth/Teachers/PasswordReset")
);

//students
const LoginStudentsScreen = lazy(() =>
    import("../../views/administrador/auth/Students/LoginScreen")
);
const ForgotMyPasswordStudents = lazy(() =>
    import("../../views/administrador/auth/Students/ForgotMyPassword")
);
const PasswordResetStudents = lazy(() =>
    import("../../views/administrador/auth/Students/PasswordReset")
);

const AdmissionApplication = lazy(() =>
    import("../../views/students/AdmissionApplication")
);

const AdmissionApplicationSucces = lazy(() =>
    import("../../views/students/AdmissionApplicationSuccesScreen")
);

export const LogOutRouter = {

    LoginScreen,
    ForgotMyPassword,
    PasswordReset,

    LoginTeachersScreen,
    ForgotMyPasswordTeacher,
    PasswordResetTeacher,

    LoginStudentsScreen,
    ForgotMyPasswordStudents,
    PasswordResetStudents,
    AdmissionApplication,
    AdmissionApplicationSucces,

};

