import dayjs from "dayjs";
import "dayjs/locale/es";

export const formatMoney = (money) =>
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(money);

export const formatTime = (time) => time.split(":").slice(0, 2).join(":");

export const formatDate = (dateString, showWeekDay = true) => {
    dayjs.locale("es");

    const date = dayjs(dateString);

    const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
    ];
    const months = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
    ];

    const weekDay = days[date.day()];
    const day = date.date();
    const month = months[date.month()];
    const year = date.year();

    return `${showWeekDay ? weekDay + " " : ""}${day} de ${month} del ${year}`;
};

export const formatDateString = (dateString, showWeekDay = true, showTime = false) => {
    dayjs.locale("es");

    const date = dayjs(dateString);

    const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
    ];
    const months = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
    ];

    const weekDay = days[date.day()];
    const day = date.date();
    const month = months[date.month()];
    const year = date.year();
    const hour = date.format("HH");
    const minute = date.format("mm");

    return `${showWeekDay ? weekDay + " " : ""}${day} de ${month} del ${year}${
        dateString.includes(" ") ? ` a las ${hour}:${minute}` : ""
    }`;
};

export const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Manejar el caso de una cadena vacía
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
