import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5
    },
    list: [],
    active: null,
    openForm: false,
    openFormUsers: false
};

export const careersReducer = (state = initialState, action) => {
           switch (action.type) {
               case types.careersShow:
                   return {
                       ...state,
                       pagination: action.payload.pagination
                   };
               case types.careersList:
                   return {
                       ...state,
                       list: action.payload
                   };
               case types.careerSetActive:
                   return {
                       ...state,
                       active: action.payload.career
                   };
               case types.careerUnsetActive:
                   return {
                       ...state,
                       active: null
                   };
               case types.careerOpenModal:
                   return {
                       ...state,
                       openForm: true
                   };
               case types.careerCloseModal:
                   return {
                       ...state,
                       openForm: false,
                       active: null
                   };
               case types.careerOpenModalUsers:
                   return {
                       ...state,
                       openFormUsers: true
                   };
               case types.careerCloseModalUsers:
                   return {
                       ...state,
                       openFormUsers: false,
                       active: null
                   };
               default:
                   return state;
           }
       };
