import React from "react";
import { Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { startUISetWatchedNotification } from "../redux/actions/administrador/ui/ui";

export const NotificationApp = ({ notification }) => {
    const { configs: text_cont } = useSelector((state) => state.ui);
    const dispath = useDispatch();
    const {
        show,
        title,
        body,
        icon,
        watched,
        click_action = "/",
    } = notification;

    const handleClose = () => {
        dispath(startUISetWatchedNotification(notification));
    };

    return (
        <Toast
            onClose={handleClose}
            show={show}
            className="animate__animated animate__bounce"
        >
            <Toast.Header>
                <img
                    src={icon}
                    height={20}
                    className="rounded mr-2"
                    alt="Noticación"
                />
                <strong className="mr-auto">{title}</strong>
            </Toast.Header>
            <a
                role="button"
                className="text-decoration-none"
                href={click_action}
            >
                <Toast.Body
                    style={{
                        backgroundColor: "#E5E5E7",
                        borderBottom: `2px solid ${text_cont}`,
                    }}
                >
                    {body}
                </Toast.Body>
            </a>
        </Toast>
    );
};

