import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";
import { startStudentSetActive } from "../../actions/students/students";

const initialState = {
    pagination: {
        data: [],
        per_page: 50,
    },
    paginationPending: {
        data: [],
        per_page: 50,
    },
    showModal: false,
    showReviewModal: false,
    active: null,
    report: {
        pagination: {
            data: [],
            per_page: 10,
            total: 0,
        },
    },
    condensed: null,
};

export const statusChange = createSlice({
    name: "statusChange",
    initialState,
    reducers: {
        setSetActive: (state, action) => {
            state.active = action.payload;
        },
        startUnsetActive: (state) => {
            state.active = null;
        },
        openModal: (state) => {
            state.showModal = true;
        },
        closeModal: (state) => {
            state.showModal = false;
        },
        openReviewModal: (state) => {
            state.showReviewModal = true;
        },
        closeReviewModal: (state) => {
            state.showReviewModal = false;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setPaginationPending: (state, action) => {
            state.paginationPending = action.payload;
        },
        setPaginationReport: (state, action) => {
            state.report.pagination = action.payload;
        },
        clearPaginationReport: (state) => {
            state.report.pagination = initialState.report.pagination;
        },
        seListReport: (state, action) => {
            state.report.list = action.payload;
        },
        setCondensed: (state, action) => {
            state.condensed = action.payload;
        },
    },
});

export const {
    setSetActive,
    startUnsetActive,
    openModal,
    closeModal,
    openReviewModal,
    closeReviewModal,
    setPagination,
    setPaginationPending,
    setPaginationReport,
    clearPaginationReport,
    seListReport,
    setCondensed,
} = statusChange.actions;

export default statusChange.reducer;

// Actions
export const startShow = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const {
            page,
            query,
            number_rows,
            request_status,
            request_date,
            review_date,
        } = values;

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);

        if (request_status.length) {
            params.set(
                "request_status",
                request_status.map((status) => status.value).join(",")
            );
        }

        if (request_date.from && request_date.to) {
            params.set("request_date_from", request_date.from);
            params.set("request_date_to", request_date.to);
        }

        if (review_date.from && review_date.to) {
            params.set("review_date_from", review_date.from);
            params.set("review_date_to", request_date.to);
        }

        const resp = await Call(`operators/students/statuses`, "GET", params);

        if (resp.success) {
            dispatch(setPagination(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startShowPending = (academic_record, values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const { page, query, number_rows } = values;

        const params = new URLSearchParams();
        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);
        params.set("request_statuses", "PENDIENTE,RECHAZADO");

        const resp = await Call(
            `operators/students/statuses/${academic_record}`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setPaginationPending(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startShowHistorial = (academic_record, values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const { page, query, number_rows } = values;

        const params = new URLSearchParams();
        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);
        params.set("request_statuses", "ACEPTADO");

        const resp = await Call(
            `operators/students/statuses/${academic_record}`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setPagination(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startSave = (
    academic_record,
    values,
    callback = () => {},
    close = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(
            `operators/students/statuses/${academic_record.id}`,
            "post",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Solicitud creada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });

            callback();
            close();
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startReview = (
    academic_record,
    academic_record_status,
    values,
    callback = () => {},
    close = () => {}
) => {
    return async (dispatch) => {
        if (values.request_status === "ACEPTADO") {
            const result = await Swal.fire({
                title: "¿Estás seguro?",
                html: `Estás a punto de aprobar la solicitud, esta acción cambiará el estatus del alumno a:<br><br>
                       <strong>"${academic_record_status.status}"</strong>.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aprobar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#2e4a6c",
                cancelButtonColor: "#6c757d",
                reverseButtons: true,
            });

            if (!result.isConfirmed) {
                return;
            }
        }

        dispatch(startUILoading());

        const resp = await Call(
            `operators/students/statuses/review/${academic_record_status.id}`,
            "post",
            values
        );

        if (resp.success) {
            if (values.request_status === "ACEPTADO") {
                dispatch(
                    startStudentSetActive({
                        ...academic_record,
                        status: resp.success.status,
                    })
                );
            }

            Swal.fire({
                title: "Éxito",
                text: "Solicitud revisada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });

            callback();
            close();
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startShowReport = ({
    page = 1,
    query = "",
    number_rows = 10,
    asList = 0,
    from,
    to,
    status,
}) => {
    return async (dispatch) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const params = new URLSearchParams();

        if (asList) {
            params.set("asList", asList);
        } else {
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
            if (from) {
                params.set("from", from);
            }
            if (to) {
                params.set("to", to);
            }
            if (status) {
                params.set("status", status);
            }
        }

        const resp = await Call(
            `operators/students/statuses/report`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setPaginationReport(resp.success));
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startShowCondensed = ({ from, to, status }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("isCondensed", 1);

        if (from) {
            params.set("from", from);
        }
        if (to) {
            params.set("to", to);
        }
        if (status) {
            params.set("status", status);
        }

        const resp = await Call(
            `operators/students/statuses/report`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setCondensed(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startExportReport = ({ from, to, status }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("export", 1);

        if (from) {
            params.set("from", from);
        }
        if (to) {
            params.set("to", to);
        }
        if (status) {
            params.set("status", status);
        }

        const response = await CallWithFormDataFile(
            `operators/students/statuses/report`,
            "GET",
            params.toString()
        );
        downloadBlob(response, "Reporte de estatus académico de estudiantes");

        dispatch(stopUILoading());
    };
};
