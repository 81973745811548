import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../../actions/administrador/ui/ui";

import Swal from "sweetalert2";
import { errorHandler } from "../../../../helpers/errorHandler";
import { Call } from "../../../../helpers/fetch";

const initialState = {
    groups: [],
    active: null,
    // TODO mover a un slice, reducer de GRADES
    grades: null,
    previousGrades: null,
    schedule: [],
};

export const teacherGroups = createSlice({
    name: "teacherGroups",
    initialState,
    reducers: {
        setGroups: (state, action) => {
            state.groups = action.payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        setGrades: (state, action) => {
            state.grades = action.payload;
        },
        setPreviousGrades: (state, action) => {
            state.previousGrades = action.payload;
        },
        setSchedule: (state, action) => {
            state.schedule = action.payload;
        },
        clearSchedule: (state) => {
            state.schedule = [];
        },
        clearGrades: (state) => {
            state.grades = initialState.grades;
        },
    },
});

export const {
    setGroups,
    setActive,
    setGrades,
    setPreviousGrades,
    setSchedule,
    clearSchedule,
    clearGrades,
} = teacherGroups.actions;

export default teacherGroups.reducer;

// Actions

export const startShowGroups = (values, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        dispatch(stopUILoading());
    };
};

export const startSetActive = (values, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        dispatch(setActive(values));
        dispatch(stopUILoading());
    };
};

export const startUnsetActive = () => {
    return async (dispatch) => {
        dispatch(startUILoading());
        dispatch(setActive(null));
        dispatch(stopUILoading());
    };
};

export const startResetGrades = () => {
    return async (dispatch) => {
        dispatch(setGrades(null));
    };
};

export const startResetPrevGrades = () => {
    return async (dispatch) => {
        dispatch(setPreviousGrades(null));
    };
};

export const startGetSchedule = (date) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.append("date", date);

        const resp = await Call(`teachers/subjectsByDate`, "GET", params);
        if (resp.success) {
            dispatch(setSchedule(resp.success));
        }
        dispatch(stopUILoading());
    };
};

/// TODO METERLO EN UN SLICE, REDUCER O ACTIONS DE GRADES

export const startGetPartialsGrades = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("academic_group_id", values.academic_group_id);
        params.set("cycle_id", values.cycle_id);
        params.set("period", values.period);
        params.set("is_compacted", values.currentSchedule.is_compacted);

        params.set(
            "school_subjects_id",
            values.currentSubject.school_subject_id
        );

        const resp = await Call(
            `teachers/grades/partials`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            // let gradesDeepCopy = JSON.parse(JSON.stringify(resp?.success?.grades));
            // let gradesDeepCopy = structuredClone(resp?.success?.grades);
            // gradesDeepCopy?.sort(({student: {student: {lastname1: a} } }, {student: {student: {lastname1: b} } }) => ( a < b ? -1 : 1 ));
            // dispatch(setGrades({...resp.success, grades: gradesDeepCopy}));

            dispatch(setGrades(resp.success));
        }

        dispatch(stopUILoading());
    };
};

export const startGetExtraGrades = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("academic_group_id", values.academic_group_id);
        params.set("cycle_id", values.cycle_id);
        params.set("period", values.period);
        params.set(
            "school_subjects_id",
            values.currentSubject.school_subject_id
        );
        params.set("is_compacted", values.currentSchedule.is_compacted);

        const resp = await Call(
            `teachers/grades/extraordinary`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            // let gradesDeepCopy = JSON.parse(JSON.stringify(resp?.success?.grades));
            // let gradesDeepCopy = structuredClone(resp?.success?.grades);
            // gradesDeepCopy?.sort(({student: {student: {lastname1: a} } }, {student: {student: {lastname1: b} } }) => ( a < b ? -1 : 1 ));
            // dispatch(setGrades({...resp.success, grades: gradesDeepCopy}));
            dispatch(setGrades(resp.success));
        }

        dispatch(stopUILoading());
    };
};

export const startGetPreviousGrades = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("academic_group_id", values.academic_group_id);
        params.set("cycle_id", values.cycle_id);
        params.set("period", values.period);
        params.set("school_subjects_id", values.school_subjects_id);

        const resp = await Call(
            `teachers/grades/show`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setPreviousGrades(resp.success));
        }

        dispatch(stopUILoading());
    };
};

export const startUploadGrades = (values, callback) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(`teachers/grades/upload`, "PUT", values);

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
            callback();
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};
