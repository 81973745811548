import { types } from "../types";

const initialState = {

};

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
       
        default:
            return state;
    }
};
